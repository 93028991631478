// General

export const translateWeekdays = {
  monday: 'Segunda',
  tuesday: 'Terça',
  wednesday: 'Quarta',
  thursday: 'Quinta',
  friday: 'Sexta',
  saturday: 'Sábado',
  sunday: 'Domingo'
};

// Portal

export const translateQuickFilter = {
  waiting: 'Aguardando Coleta',
  started: 'Iniciado',
  picked: 'Coletado',
  packed: 'Empacotado',
  canceled: 'Cancelado',
  separated: 'Embarque',
  dispatched: 'Em trânsito',
  delivering: 'Chegando',
  delivered: 'Entregue',
  billed: 'Faturado',
  orderAlert: 'Pedido em Alerta',
  etaMax: 'Pedido em atraso',
  solicitaDevolucao: 'Solicitação de Devolução',
  erroNota: 'Erro na Nota',
  clearFilters: 'Limpar'
};

export const translatedFiltersToEnglish = {
  // Status: 'erp_status_changed',
  // Email: 'email_sent',
  // Alerta: 'alert',
  Ligação: 'phone_call',
  Comentários: 'comment',
  'Pedido com incidência': 'order_alert',
  'Incidência resolvida': 'order_alert_resolved',
  'Item devolvido': 'returned_item'
};

export const translateHighlightsLabel = {
  product: 'Produto',
  category: 'Categoria',
  manufacturer: 'Fabricante'
};

export const subscriptionStatus = {
  skipped: 'Pulado',
  skipping: 'Pulado',
  paused: 'Em pausa',
  paid: 'Pago',
  waiting_payment: 'Aguardando pagamento',
  payment_error: 'Erro de pagamento',
  inactive: 'Inativo',
  active: 'Ativo',
  initialized: 'Inicializado'
};

// Dispatch

export const dispatchStatus = {
  waiting: 'Aguardando',
  started: 'Iniciado',
  picked: 'Coletado',
  packed: 'Empacotado',
  separated: 'Embarque',
  billed: 'Faturado',
  dispatched: 'Em rota',
  delivering: 'Chegando',
  delivered: 'Entregue'
};

// Petz

export const translateRefundMethod = {
  wallet: 'créditos na carteira',
  credit_card_reversal: 'estorno',
  redelivery: 'reenvio/transportadora',
  pix_reversal: 'pix'
};

export const translateTicketOrigin = {
  petz: 'Petz',
  storefront: 'Cliente',
  portal: 'Zee'
};

export const translateRequestReasons = {
  delivery_delay: 'Atraso na entrega',
  damaged_or_expired: 'Avaria ou vencido',
  carrier_loss: 'Extravio na transportadora',
  customer_made_wrong_purchase: 'Cliente efetuou a compra errada',
  purchase_regret: 'Arrependimento de compra',
  defect_free_exchange: 'Troca sem defeito',
  customer_received_wrong_product: 'Cliente recebeu produto errado',
  product_dissatisfaction: 'Insatisfação do produto',
  customer_did_not_receive_some_item: 'Cliente não recebeu algum item',
  cancelled_purchase: 'Desisti da compra',
  will_reorder_with_different_items: 'Vou refazer com outro(s) item(ns)',
  other: 'Outros',
  not_identified: 'Não identificado',
  unserved_zipcode: 'CEP não atendido',
  customer_provided_wrong_address: 'Cliente informou endereço errado',
  improper_return_from_the_carrier: 'Devolução indevida da transportadora',
  po_box_address: 'Endereço de caixa postal',
  wrong_delivery_address: 'Endereço de entrega errado',
  store_misplacement: 'Extravio loja',
  technical_failure: 'Falha técnica',
  missing_item_in_separation: 'Faltou item na separação',
  fraud: 'Fraude',
  not_make_this_purchase: 'Não fiz essa compra',
  defective_product_not_working: 'Produto com defeito, não está funcionando',
  received_late: 'Recebido com atraso',
  carrier_did_not_find_customer: 'Transportadora não localizou cliente',
  cancellation_forced: 'Cancelamento forçado por erro de integração'
};

export const translateTicketStatus = {
  pending: 'Em processamento com a Petz',
  success: 'Petz processou o cancelamento',
  rejected: 'Petz rejeitou o cancelamento',
  canceled: 'Petz cancelou o cancelamento'
};
