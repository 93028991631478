import { createSlice } from '@reduxjs/toolkit';

import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  productsList: [],
  count: 0,
  query: {
    page: 1,
    perPage: 10,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  isLoading: false
};

const allProductsSlice = createSlice({
  name: '@allProducts',
  initialState,
  reducers: {
    setAllProducts(prevState, action) {
      const state = prevState;
      state.productsList = action.payload.productsList;
      state.count = action.payload.count;
      state.isLoading = false;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    setQuery(prevState, action) {
      const state = prevState;
      state.query = {
        page: Number(action.payload.page || state.query.page),
        perPage: action.payload.perPage || state.query.perPage,
        search: action.payload.search || '',
        sort: action.payload.sort || '',
        filters: action.payload.filters || {},
        url: `?page=${action.payload.page || '1'}&per_page=${action.payload
          .perPage || state.query.perPage}${
          action.payload.search ? `&q=${action.payload.search}` : ''
        }${action.payload.sort ? `&sort${action.payload.sort}` : ''}${
          action.payload.filters
            ? buildQueryStringFromObj(action.payload.filters)
            : ''
        }`
      };
    },
    getAllProducts(state) {
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    exportCSV(state) {
      return state;
    },
    exportCSVProductsSort(state) {
      return state;
    },
    importCSVProductsSort(state) {
      return state;
    },
    importBuyAndEarnPromotions(state) {
      return state;
    },
    importKWFile(state) {
      return state;
    }
  }
});

export const {
  setAllProducts,
  setLoading,
  setQuery,
  getAllProducts,
  setPagination,
  exportCSV,
  exportCSVProductsSort,
  importCSVProductsSort,
  importBuyAndEarnPromotions,
  importKWFile
} = allProductsSlice.actions;

export default allProductsSlice.reducer;
