import React from 'react';
import { Card, Row, Col, Checkbox } from 'antd';
import PropTypes from 'prop-types';

const CreditCards = props => {
  const { creditCard, editPermission } = props;

  return (
    <Card className="no-shadow" title={<h2>Cartões</h2>}>
      {creditCard.length > 0 ? (
        <Row gutter={[8, 8]} type="flex" className="car-addresses">
          {creditCard.map(cards => (
            <Card key={cards.id} style={{ width: 250 }}>
              <Row type="flex">
                <Col span={4}>
                  <Checkbox
                    checked={cards.attributes.selected}
                    disabled={!cards.attributes.selected || !editPermission}
                    className="customer-info-checkbox"
                  />
                </Col>
                <Col span={18} offset={1}>
                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: 15,
                      fontWeight: 'bold'
                    }}
                  >
                    XXXX XXXX XXXX {cards.attributes.number}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    Nome: {cards.attributes.name}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    Bandeira: {cards.attributes.card_type}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    Expiração: {cards.attributes.expiration}
                  </p>
                  {/* <p
                    style={{
                      marginBottom: 0,
                      textTransform: 'uppercase',
                      color: '#46ecc5',
                      fontWeight: 'bold'
                    }}
                  >
                    ativação
                  </p> */}
                </Col>
              </Row>
            </Card>
          ))}
        </Row>
      ) : (
        <p>Cliente não tem cartões cadastrados</p>
      )}
    </Card>
  );
};

CreditCards.propTypes = {
  creditCard: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        id: PropTypes.number,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        customer_id: PropTypes.number,
        selected: PropTypes.bool,
        card_type: PropTypes.string,
        number: PropTypes.string,
        expiration: PropTypes.string,
        device: PropTypes.string,
        bin: PropTypes.string,
        name: PropTypes.string
      })
    })
  ).isRequired,
  editPermission: PropTypes.bool.isRequired
};

export default CreditCards;
