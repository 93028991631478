import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, message, Upload, Button } from 'antd';
import ModalCSV from '@/src/components/ModalCSV/index';
import { importCSVOrderDelay } from '@/src/store/modules/orders/slice';
import PropTypes from 'prop-types';

export const ModalCSVContent = ({ showCSVModal, setShowCSVModal }) => {
  const dispatch = useDispatch();
  const [CSVFile, setCSVFile] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const conditions = ['.csv', '.xlsx', '.xls'];
  const checkCSVType = file => conditions.some(el => file.name.includes(el));

  const beforeUpload = file => {
    checkCSVType(file);
    if (!checkCSVType(file)) {
      message.error('Apenas arquivos CSV são aceitos!', 5);
      setBtnDisabled(true);
      return false;
    }
    setCSVFile([file]);
    setBtnDisabled(false);
    return false;
  };

  const removeFile = () => {
    setCSVFile([]);
    setBtnDisabled(showCSVModal);
  };

  const handleImportCSV = useCallback(() => {
    dispatch(importCSVOrderDelay({ csv: CSVFile }));
    setShowCSVModal(false);
    setBtnDisabled(false);
    setCSVFile([]);
    // eslint-disable-next-line
  }, [CSVFile]);

  return (
    <ModalCSV
      titleModal="IMPORTAR PEDIDOS ATRASADOS"
      isVisible={showCSVModal}
      setIsVisibleModalCSV={() => setShowCSVModal(false)}
      CSVFileHandle={handleImportCSV}
      buttonDisabled={btnDisabled}
      components={
        <>
          <Row
            style={{
              borderBottom: '3px ##E2E2E2 solid',
              borderTop: '3px ##E2E2E2 solid'
            }}
          >
            <Col style={{ marginBottom: 10 }}>
              <strong>
                Importante: sempre que uma planilha for atualizada, ela irá
                sobrescrever todas as informações existentes.
              </strong>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 25
              }}
            >
              <Upload
                className="upload-csv-file-halls"
                beforeUpload={beforeUpload}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onRemove={removeFile}
                fileList={CSVFile}
              >
                <Button className="ant-btn-secondary-copy">
                  Escolher Arquivo
                </Button>
              </Upload>
              {CSVFile.length === 0 && (
                <p style={{ marginBottom: 0, marginLeft: 10 }}>
                  Nenhum arquivo selecionado.
                </p>
              )}
            </Col>
          </Row>
        </>
      }
     />
  );
};

ModalCSVContent.propTypes = {
  showCSVModal: PropTypes.bool.isRequired,
  setShowCSVModal: PropTypes.func.isRequired
};

export default ModalCSVContent;
