export const USERS = 'auth.users.manage';
export const ADMIN = 'auth.users.manage_admin';
export const DELIVERER = 'auth.users.manage_deliverer';
export const STOCKIST = 'auth.users.manage_stockist';

export const ORDERS = 'admin_storefront.orders.manage';

export const SUBSCRIPTIONS_MANAGE = 'admin_storefront.subscriptions.manage';
export const SUBSCRIPTIONS_VIEW = 'admin_storefront.subscriptions.view';

export const PROMOTIONS = 'admin_storefront.promotions.manage';

export const REFERRAL = 'admin_storefront.referral.manage';

// export const REPORTS_GENERAL = 'admin_storefront.reports.manage_general';
// export const REPORTS_COMMERCIAL = 'admin_storefront.reports.manage_commercial';
export const REPORTS_GROWTH = 'admin_storefront.reports.manage_growth';
// export const REPORTS_OPERATIONS = 'admin_storefront.reports.manage_operations';

export const CUSTOMERS_VIEW = 'admin_storefront.clients.view';
export const CUSTOMERS_MANAGE = 'admin_storefront.clients.manage';
export const CREDITS = 'admin_storefront.clients.manage_credits';
export const WALLET = 'admin_storefront.customers_wallet.manage';
export const REMOVE_CUSTOMER = 'admin_storefront.clients.manage_customers';
export const IMPERSONATE_CUSTOMER = 'admin_storefront.clients.impersonate';

export const CATALOG = 'admin_storefront.catalog.manage';
export const SKUSPETZ = 'admin_storefront.catalog.manage_skus_petz';
export const CUSTOM_FIELDS = 'admin_storefront.custom_fields.manage';

export const GATEWAY_STORES = 'admin_storefront.gateway_stores.manage';

export const DISPATCH_ORDERS = 'dispatch.orders.manage';
export const DISPATCH_ORDERS_VIEW = 'dispatch.orders.view';
export const DISPATCH_ORDERS_PICKPACK = 'dispatch.orders.manage_pick_and_pack';
export const DISPATCH_EMBARKS = 'dispatch.embarks.manage';
export const DISPATCH_HUBS = 'dispatch.hubs.manage';
export const DISPATCH_HUBS_UPDATE = 'dispatch.hubs.update_in_batch';
export const DISPATCH_HUBS_INVOICE =
  'dispatch.hubs.manage_embark_require_invoice';
export const DISPATCH_DELIVERERS = 'dispatch.deliveries.manage';
export const DISPATCH_HUB_SUSPENSION = 'dispatch.hubs.manage_suspension';

export const PASSWORD = 'password';

// a propriedade readBy nos objetos do array abaixo indica módulos com dependência na permissão em questão, ou seja, módulos que executam requisições relacionadas àquela permissão. por exemplo, o módulo Pedidos precisa ter uma versão "read only" para usuários com permissão em Clientes ou Indicações, mas que não possuem permissão em Pedidos

export const data = [
  {
    permission: ORDERS,
    name: 'Pedidos',
    url: '/pedidos',
    readBy: [CUSTOMERS_VIEW, REFERRAL, SUBSCRIPTIONS_VIEW]
  },
  {
    permission: SUBSCRIPTIONS_VIEW,
    name: 'Assinaturas (apenas visualização)',
    url: '/assinaturas',
    readBy: [],
    showOnly: 'zeedog'
  },
  {
    permission: SUBSCRIPTIONS_MANAGE,
    name: 'Gestão de assinaturas',
    readBy: [],
    submodule: true,
    showOnly: 'zeedog'
  },
  {
    permission: CUSTOMERS_VIEW,
    name: 'Clientes (apenas visualização)',
    url: '/clientes',
    readBy: [ORDERS]
  },
  {
    permission: CUSTOMERS_MANAGE,
    name: 'Gestão de clientes',
    readBy: [],
    submodule: true
  },
  {
    permission: CREDITS,
    name: 'Créditos',
    readBy: [],
    submodule: true
  },
  {
    permission: WALLET,
    name: 'Carteira',
    readBy: [],
    submodule: true
  },
  {
    permission: REMOVE_CUSTOMER,
    name: 'Excluir cadastro de cliente',
    readBy: [],
    submodule: true
  },
  {
    permission: IMPERSONATE_CUSTOMER,
    name: 'Personificar cliente',
    readBy: [],
    submodule: true
  },
  {
    permission: CATALOG,
    name: 'Catálogo',
    url: '/atributos',
    readBy: [PROMOTIONS]
  },
  {
    permission: CUSTOM_FIELDS,
    name: 'Campos customizados',
    readBy: [],
    submodule: true
  },
  {
    permission: SKUSPETZ,
    name: 'SKUs Petz',
    url: '/skus-petz',
    submodule: true,
    readBy: []
  },
  {
    permission: PROMOTIONS,
    name: 'Promoções',
    url: '/promocoes',
    readBy: []
  },
  {
    permission: REFERRAL,
    name: 'Indicações',
    url: '/indicacoes',
    readBy: [],
    showOnly: 'zeenow'
  },
  {
    permission: DISPATCH_HUBS,
    name: 'Hubs',
    url: '/hubs',
    readBy: [],
    showOnly: 'zeenow'
  },
  {
    permission: DISPATCH_HUBS_INVOICE,
    name: 'Restrição de NF para embarque',
    readBy: [],
    submodule: true,
    showOnly: 'zeenow'
  },
  {
    permission: DISPATCH_HUB_SUSPENSION,
    name: 'Suspensão de hubs',
    readBy: [],
    submodule: true,
    showOnly: 'zeenow'
  },
  {
    permission: DISPATCH_HUBS_UPDATE,
    name: 'Upload de arquivo ETA/Suspensão',
    readBy: [],
    submodule: true,
    showOnly: 'zeenow'
  },
  {
    permission: DISPATCH_ORDERS,
    name: 'Expedição',
    url: '/expedicao',
    readBy: []
  },
  {
    permission: DISPATCH_ORDERS_VIEW,
    name: 'Visualização de pedidos (apenas TV)',
    readBy: [],
    submodule: true
  },
  {
    permission: DISPATCH_ORDERS_PICKPACK,
    name: 'Coleta de pedidos',
    readBy: [],
    submodule: true
  },
  {
    permission: DISPATCH_EMBARKS,
    name: 'Embarques',
    url: '/embarques',
    readBy: [],
    showOnly: 'zeenow'
  },
  {
    permission: DISPATCH_DELIVERERS,
    name: 'Entregadores',
    url: '/entregadores',
    readBy: [],
    showOnly: 'zeenow'
  },
  // {
  //   permission: REPORTS_GENERAL,
  //   name: 'Relatório Geral',
  //   url: '/relatorios/comparativo',
  //   readBy: []
  // },
  // {
  //   permission: REPORTS_COMMERCIAL,
  //   name: 'Relatório Comercial',
  //   url: '/relatorios/comercial',
  //   readBy: []
  // },
  {
    permission: REPORTS_GROWTH,
    name: 'Relatórios',
    url: '/relatorios/faturamento/vendas',
    readBy: []
  },
  // {
  //   permission: REPORTS_OPERATIONS,
  //   name: 'Relatório Operações',
  //   url: '/relatorios/operacoes/zeeman',
  //   readBy: []
  // },
  {
    permission: USERS,
    name: 'Usuários',
    url: '/usuarios',
    readBy: []
  },
  {
    permission: ADMIN,
    name: 'Administrador',
    url: '/usuarios',
    readBy: [],
    submodule: true
  },
  {
    permission: STOCKIST,
    name: 'Estoquista',
    url: '/usuarios',
    readBy: [],
    submodule: true
  },
  {
    permission: DELIVERER,
    name: 'Entregador',
    url: '/usuarios',
    readBy: [],
    submodule: true
  },
  {
    permission: GATEWAY_STORES,
    name: 'Lojas com Gateway de Pagamento',
    url: '/',
    readBy: []
  }
];
