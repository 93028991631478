import { apiDispatch } from '@/src/api/config';
import { dispatchQueryBuilder } from '@/src/utils/queryBuilder';

export const getEtasData = payload => {
  const url = dispatchQueryBuilder(payload, '/eta_schedules');
  return apiDispatch.get(url);
};

export const getEtaSingleData = id => {
  return apiDispatch.get(`/eta_schedules/${id}`);
};

export const setUpdateEtaData = payload =>
  apiDispatch.patch(`/eta_schedules/${payload.data.id}`, payload);

export const setCreateEtaData = payload =>
  apiDispatch.post('/eta_schedules', payload);

export const setDeleteEtaData = id =>
  apiDispatch.delete(`/eta_schedules/${id}`);

export const exportCSVEtaBatchData = payload =>
  apiDispatch.get('/exports/hubs', payload);
