import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Row, Button, Col, Switch, Select, Modal, Avatar } from 'antd';
import Tables from '@/src/components/Tables/index';
import Searchbar from '@/src/components/Searchbar/Searchbar';

import ModalCSV from '@/src/components/Recommendations/ModalCSV/index';

import {
  getRecommendations,
  getUpdateRecommendation,
  setPagination
} from '@/src/store/modules/recommendations/slice';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

export const RecommendationsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const typeTable = 'recommendations';

  const { Option } = Select;

  const [statusModal, setStatusModal] = useState(false);
  const [modalRecomData, setModalRecomData] = useState({});

  const [typeSelectedValue, setTypeSelectedValue] = useState([]);

  const [showCSVModal, setCSVModal] = useState(false);

  const getRecommendationsHandler = useCallback(
    urlQuery => {
      dispatch(getRecommendations({ ...urlQuery }));
    },
    [dispatch]
  );

  const { recommendations, isLoading, query, count } = useSelector(
    state => state.recommendations
  );

  useEffect(() => {
    let filter;
    if (query.filters && query?.filters.by_recommType) {
      filter = query.filters.by_recommType;
    }
    setTypeSelectedValue(filter);
  }, [query]);

  useEffect(() => {
    const params = buildQueryFromUrl(history, {
      sort: '[id]=asc'
      // filters: {
      //   by_recommType: ''
      // }
    });

    getRecommendationsHandler({ query: params });
  }, [getRecommendationsHandler, history]);

  const setPaginationHandler = useCallback(
    payload => {
      dispatch(setPagination({ ...payload }));
    },
    [dispatch]
  );

  const handleStatusSwitch = info => {
    setModalRecomData(info);
    setStatusModal(true);
  };

  const confirmStatusChange = () => {
    const newStatus = !modalRecomData.status;

    const payload = {
      updateData: {
        type: modalRecomData.group_id,
        spotlight_product: Number(modalRecomData.spotlight_product.id),
        active: newStatus ? 1 : 0,
        recommendations: modalRecomData.products.map(item =>
          Number(item.product.id)
        ),
        id: modalRecomData.id
      },
      updateSort: {
        id: modalRecomData.id,
        products: modalRecomData.products.map(item => item.product)
      }
    };

    dispatch(getUpdateRecommendation(payload));
    setStatusModal(false);
  };

  const TableColumns = [
    {
      title: 'Número',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: number => {
        return (
          <Link to={`/recomendacoes/${number}`}>
            <div style={{ textAlign: 'center', color: '#000' }}>{number}</div>
          </Link>
        );
      }
    },
    {
      title: 'Tipo',
      dataIndex: 'group_name',
      key: 'recommendation_type',
      render: (type, info) => {
        return (
          <Link to={`/recomendacoes/${info.id}`}>
            <div style={{ color: '#000' }}>{type}</div>
          </Link>
        );
      }
    },
    {
      title: 'Produto Associado',
      dataIndex: 'spotlight_product.title',
      key: 'associated_product',
      render: (product, info) => {
        const url = info.images
          ? info.images[1]?.url || info.images[0]?.url || info.images?.url
          : '';
        return (
          <Link to={`/recomendacoes/${info.id}`} style={{ color: '#000' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                shape="square"
                size={30}
                src={url}
                alt={product}
                icon="camera"
                className="img-placeholder"
                style={{ marginRight: 10 }}
              />
              {product}
            </div>
          </Link>
        );
      }
    },
    {
      title: 'Qtd de Produtos',
      dataIndex: 'products.length',
      key: 'product_quantity',
      align: 'center'
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Status</span>
        </div>
      ),
      align: 'center',
      dataIndex: 'status',
      key: '4',
      render: (status, info) => {
        return (
          <Switch
            defaultChecked={status}
            onChange={() => handleStatusSwitch(info)}
          />
        );
      }
    }
  ];

  return (
    <>
      <Modal
        visible={statusModal}
        title="ALTERAR SITUAÇÃO"
        onCancel={() => setStatusModal(false)}
        onOk={() => confirmStatusChange()}
        centered
        className="ant-modal-recom-editStatus"
        okText="Alterar"
      >
        <span>
          Você selecionou para mudar a situação da recomendação #
          {modalRecomData.id}. Alteração será refletida no app automaticamente.
        </span>
        <p>Tem certeza que deseja realizar essa ação?</p>
      </Modal>

      <ModalCSV showModal={showCSVModal} setShowModal={setCSVModal} />

      <Row
        type="flex"
        justify="space-between"
        style={{ marginBottom: '0.5em' }}
      >
        <div>
          <h1 style={{ marginBottom: 0 }}>Recomendações</h1>
        </div>
        <div>
          <Button
            className="ant-btn ant-btn-primary"
            onClick={() => history.push('/recomendacoes/nova')}
          >
            Nova Recomendação
          </Button>
        </div>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '24px 14px',
          marginTop: '20px'
        }}
      >
        <Row
          type="flex"
          justify="space-between"
          style={{ alignItems: 'center' }}
        >
          <Col span={18}>
            <Col span={8} style={{ width: '20%' }}>
              <Select
                mode="multiple"
                placeholder="Todos os tipos"
                onFocus={() => {}}
                size="large"
                style={{ width: '100%', height: '44px' }}
                value={typeSelectedValue}
                onChange={value => {
                  const queryParams = {
                    ...query,
                    filters: value.length ? { by_recommType: value } : {}
                  };
                  getRecommendationsHandler({ query: queryParams });
                }}
                className="ant-select-filter"
              >
                <Option key={1}>Conjunto</Option>
                <Option key={2}>Upsell</Option>
                <Option key={3}>Bundle 1</Option>
                <Option key={4}>Bundle 2</Option>
              </Select>
            </Col>
            <Col
              span={10}
              style={{ width: 'calc(80% + 1px)', marginLeft: '-1px' }}
              className="ant-searchbar-filter"
            >
              <Searchbar
                placeholder="Buscar por nome do produto"
                populateResult={getRecommendationsHandler}
                query={query}
                value={query.search}
              />
            </Col>
          </Col>
          <Col>
            <Button
              onClick={() => setCSVModal(true)}
              size="large"
              className="ant-btn-secondary"
              style={{ padding: '0 8px' }}
            >
              importar recomendações
            </Button>
          </Col>
        </Row>
        <br />
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <Tables
              rowKey="id"
              className="even-gray"
              contentToShow={typeTable}
              visiblePaginationUp
              showCountFilters
              showCountText="recomendações listadas"
              isLoading={isLoading}
              data={recommendations}
              columnsDefaults={TableColumns}
              history={history}
              count={count}
              query={query}
              setPaginationState={setPaginationHandler}
              populateTables={getRecommendationsHandler}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RecommendationsPage;
