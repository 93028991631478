import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loginFailed: false,
  isLoading: false,
  returnUrl: '',
  loginData: {},
  newPassword: false
};

const loginSlice = createSlice({
  name: '@login',
  initialState,
  reducers: {
    loginAccount(state) {
      return state;
    },
    logoutAccount(state) {
      return state;
    },
    getReturnUrl(state) {
      return state.returnUrl;
    },
    setReturnUrl(prevState, action) {
      const state = prevState;
      state.returnUrl = action.payload;
      return state;
    },
    loginAccountSuccess(prevState, action) {
      const state = prevState;
      state.loginFailed = false;
      state.isLoading = false;
      state.loginData = action.payload;
      return state;
    },
    loginAccountFailed(prevState, action) {
      const state = prevState;
      state.loginFailed = true;
      state.isLoading = false;
      state.loginData = action.payload;
      return state;
    },
    loginLoading(prevState) {
      const state = prevState;
      state.isLoading = true;
      return state;
    },
    newPassword(prevState, action) {
      const state = prevState;
      state.newPassword = action.payload;
      return state;
    }
  }
});

export const {
  loginAccountSuccess,
  loginAccountFailed,
  loginLoading,
  logoutAccount,
  loginAccount,
  newPassword,
  setReturnUrl,
  getReturnUrl
} = loginSlice.actions;

export default loginSlice.reducer;
