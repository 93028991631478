import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Menu, Icon, Dropdown, Modal } from 'antd';

import Audit from '@/src/components/Audit/index';

const OrderDetails = props => {
  const {
    order,
    handleFraud,
    handleFraudChargeback,
    fraud,
    editPermission
  } = props;
  const { confirm } = Modal;
  const [showAudit, setShowAudit] = useState(false);

  const showConfirmFraud = () => {
    confirm({
      title: `MARCAR PEDIDO COMO FRAUDE`,
      content: `Deseja realmente marcar esse pedido como fraude? Ao confirmar, não será possível reverter a ação.`,
      okText: 'Confirmar',
      centered: true,
      okType: 'primary',
      cancelText: 'Cancelar',
      cancelButtonProps: {
        type: 'secondary'
      },
      onOk() {
        handleFraud();
      }
    });
  };

  const showConfirmFraudChargeback = () => {
    confirm({
      title: `MARCAR PEDIDO COMO CHARGEBACK`,
      content: `Deseja realmente marcar esse pedido como chargeback? Ao confirmar, não será possível reverter a ação.`,
      okText: 'Confirmar',
      centered: true,
      okType: 'primary',
      cancelText: 'Cancelar',
      cancelButtonProps: {
        type: 'secondary'
      },
      onOk() {
        handleFraudChargeback();
      }
    });
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
      {fraud ? null : (
        <Menu.Item key="1" onClick={showConfirmFraud}>
          Marcar como fraude
        </Menu.Item>
      )}
      {fraud ? null : (
        <Menu.Item key="2" onClick={showConfirmFraudChargeback}>
          Marcar como chargeback
        </Menu.Item>
      )}
    </Menu>
  );

  const userActions = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <>
      <Audit
        id={order.id}
        title="PEDIDO"
        type="Order"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <section>
        <Card
          className="no-shadow space-left"
          title="Detalhes do pedido"
          style={{ width: '100%' }}
          extra={editPermission && userActions}
        >
          <div>
            <h4 className="text-bold">Endereço de entrega</h4>
            <p className="pl-10 mb-5">
              <span>
                {order.address_street
                  ? `${order.address_street} ${order.address_number}, ${
                      order.address_complement ? order.address_complement : ''
                    } \n`
                  : '-'}
                <br />
                {order.address_street
                  ? `${order.address_zipcode}, ${order.address_district} - ${order.address_city}`
                  : '-'}
              </span>
            </p>
          </div>
          <div>
            <h4 className="text-bold">Forma de pagamento</h4>
            <p className="pl-10 mb-5">
              {order.payment_method === 'credit_card' ? (
                <span>
                  {order.credit_card_name
                    ? `${order.credit_card_name} - Exp.: ${order.credit_card_expiration} \n`
                    : '-'}
                  <br />
                  {order.credit_card_type
                    ? `${order.credit_card_type} - XXXX XXXX XXXX ${order.credit_card_number}`
                    : '-'}
                </span>
              ) : (
                <span>
                  {order.payment_method === 'pix'
                    ? 'Pix'
                    : 'Sem método de pagamento'}
                </span>
              )}
            </p>
          </div>
          <div>
            <h4 className="text-bold">Observações de pagamento</h4>
            <p className="pl-10 mb-5">
              <span>
                ID Pagar.me: {order.payment_id || 'Não há identificador'}
              </span>
            </p>
          </div>
          <div>
            <h4 className="text-bold">Observações de entrega</h4>
            <p className="pl-10 mb-5">
              {order.remarks ? (
                <span>{order.remarks}</span>
              ) : (
                'Não há observação'
              )}
            </p>
          </div>
          {/* <div>
          <h4 className="text-bold">Avaliação do pedido pelo cliente</h4>
          <p className="pl-10 mb-5">Falta implementar no backend</p>
        </div> */}
        </Card>
      </section>
    </>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    created_at: PropTypes.string,
    hub: PropTypes.shape({}),
    address_street: PropTypes.string,
    address_number: PropTypes.string,
    address_complement: PropTypes.string,
    address_zipcode: PropTypes.string,
    address_district: PropTypes.string,
    address_city: PropTypes.string,
    credit_card_name: PropTypes.string,
    credit_card_expiration: PropTypes.string,
    credit_card_type: PropTypes.string,
    credit_card_number: PropTypes.string,
    status: PropTypes.string,
    customer_name: PropTypes.string,
    customer_document: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    remarks: PropTypes.string,
    fraud_type: PropTypes.string,
    payment_method: PropTypes.string,
    payment_id: PropTypes.string
  }).isRequired,
  handleFraud: PropTypes.func.isRequired,
  handleFraudChargeback: PropTypes.func.isRequired,
  fraud: PropTypes.bool.isRequired,
  editPermission: PropTypes.bool.isRequired
};

export default React.memo(OrderDetails);
