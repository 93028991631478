const validateJson = value => {
  if (!value) return false;
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

export default validateJson;
