import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Select, Button, Form, Spin, Dropdown, Menu } from 'antd';
import PropTypes from 'prop-types';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';
import { handleSelectSearch } from '@/src/utils/searchUtils';

import Tables from '@/src/components/Tables';
import Searchbar from '@/src/components/Searchbar/Searchbar';
import FilteredTags from '@/src/components/Filters/FilteredTags/index';
import ModalProductsSort from '@/src/components/Products/ModalCSV/index';
import ModalHubSkus from '@/src/components/Products/ModalHubSkus';
import ModalBuyAndEarn from '@/src/components/Products/ModalBuyAndEarn';

import { exportCSV } from '@/src/store/modules/products-all/slice';
import { getDepartments } from '@/src/store/modules/departments/slice';
import { getCategories } from '@/src/store/modules/categories/slice';
import { getHubs } from '@/src/store/modules/hubs/slice';
import { getManufacturers } from '@/src/store/modules/manufacturers/slice';
import { getUpdateProduct } from '@/src/store/modules/product-details/slice';

import useProductsFilter from '@/src/hooks/useProductsFilter';
import FilterMenu from '@/src/pages/Filters/index';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';
import ModalKW from '@/src/components/Products/ModalKW/index';

const { OptGroup, Option } = Select;
export const ProductsPage = ({ form }) => {
  const { getFieldDecorator, setFieldsValue, resetFields } = form;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    hubFilter,
    handleProductsFilter,
    getProductsHandler
  } = useProductsFilter();
  const [tableData, setTableData] = useState([]);
  const [filteredQuery, setFilteredQuery] = useState({});
  const [showSortModal, setShowSortModal] = useState(false);
  const [showHubSkusModal, setShowHubSkusModal] = useState(false);
  const [showBuyAndEarn, setShowBuyAndEarn] = useState(false);
  const [showKWModal, setShowKWModal] = useState(false);

  const { count, productsList, query, isLoading } = useSelector(
    state => state.allProducts
  );
  const { categories, isLoading: categoriesLoading } = useSelector(
    state => state.categories
  );
  const { departments, isLoading: departmentsLoading } = useSelector(
    state => state.departments
  );
  const { manufacturers, isLoading: manufacturersLoading } = useSelector(
    state => state.manufacturers
  );
  const { hubs, isLoading: hubsLoading } = useSelector(state => state.hubs);

  const { editPermission } = usePermissions();
  const catalogPermission = editPermission(modules.CATALOG);

  const generalQuery = perPage => ({
    query: {
      sort: '',
      page: 1,
      perPage,
      search: ''
    }
  });

  useEffect(() => {
    if (query.filters) {
      const queryObj = {
        filters: { ...query.filters }
      };
      Object.keys(query.filters).forEach(key => {
        if (
          key === 'by_hub' ||
          key === 'active' ||
          key === 'not_active' ||
          key === 'by_manufacturer' ||
          key === 'by_category'
        ) {
          delete queryObj.filters[key];
        }
      });
      setFilteredQuery(queryObj);
    }
  }, [query]);

  useEffect(() => {
    const data = productsList?.map(item => ({
      ...item,
      hubsSelected: hubFilter,
      changeVisibility: value => {
        dispatch(getUpdateProduct(value));
      },
      editPermission: catalogPermission
    }));
    setTableData(data);
    // eslint-disable-next-line
  }, [dispatch, productsList, hubFilter, getProductsHandler, history]);

  const populateCategories = () => {
    if (!categories.length || !departments.length) {
      dispatch(getCategories(generalQuery(200)));
      dispatch(getDepartments(generalQuery(20)));
    }
  };

  const populateManufacturers = () => {
    if (!manufacturers.length) {
      dispatch(getManufacturers(generalQuery(500)));
    }
  };

  const populateHubs = () => {
    if (!hubs.length) {
      dispatch(getHubs(generalQuery(500)));
    }
  };

  useEffect(() => {
    const params = buildQueryFromUrl(history, { perPage: 10 });
    getProductsHandler({ query: params });
    if (params.filters?.by_hub) populateHubs();
    if (params.filters?.by_category) populateCategories();
    if (params.filters?.by_manufacturer) populateManufacturers();
    // eslint-disable-next-line
  }, [history, getProductsHandler]);

  const renderDropdownItens = (type, items, Grouplabel) => {
    const unsorted = items?.length > 0 ? [...items] : [];
    const sorted = unsorted.sort((a, b) =>
      a.attributes.name.localeCompare(b.attributes.name)
    );

    const structure = sorted.map(item => {
      const { name, internal_id } = item.attributes;
      const id = type === 'hubs' ? internal_id : item.attributes.id;

      return (
        <Option key={id} value={id}>
          {name}
        </Option>
      );
    });

    return structure;
  };

  useEffect(() => {
    if (query?.filters?.active) {
      setFieldsValue({ status: 'active' });
    } else if (query?.filters?.not_active) {
      setFieldsValue({ status: 'disabled' });
    } else {
      resetFields('status');
    }
    if (query?.filters?.by_manufacturer) {
      setFieldsValue({ manufacturer: Number(query?.filters?.by_manufacturer) });
    } else {
      resetFields('manufacturer');
    }
    if (query?.filters?.by_category) {
      setFieldsValue({ category: Number(query?.filters?.by_category) });
    } else {
      resetFields('category');
    }
  }, [query, setFieldsValue, resetFields]);

  const handleExportCSV = () => {
    const params = buildQueryFromUrl(history);
    dispatch(exportCSV({ query: params }));
  };

  const actions = (
    <Menu>
      <Menu.Item key="export-csv" onClick={handleExportCSV}>
        Exportar produtos
      </Menu.Item>
      <Menu.Item key="import-keywords" onClick={() => setShowKWModal(true)}>
        Importar palavras-chave
      </Menu.Item>
      <Menu.Item key="sort-products" onClick={() => setShowSortModal(true)}>
        Ordenar por planilha
      </Menu.Item>
      <Menu.Item key="hub-stock" onClick={() => setShowHubSkusModal(true)}>
        Estoque por hub
      </Menu.Item>
      <Menu.Item key="compre-ganhe" onClick={() => setShowBuyAndEarn(true)}>
        Compre e ganhe
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <ModalProductsSort
        showModal={showSortModal}
        setShowModal={setShowSortModal}
      />
      <ModalKW showModal={showKWModal} setShowModal={setShowKWModal} />
      <ModalHubSkus
        visible={showHubSkusModal}
        setVisible={setShowHubSkusModal}
        populateHubs={populateHubs}
        hubsLoading={hubsLoading}
      />
      <ModalBuyAndEarn
        visible={showBuyAndEarn}
        setVisible={setShowBuyAndEarn}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1 className="mb-20">Produtos</h1>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px',
          minHeight: '48px'
        }}
      >
        <div className="filters_search">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            gutter={8}
            style={{ marginBottom: 16 }}
          >
            <Col style={{ flexGrow: 1 }}>
              <Searchbar
                placeholder="Buscar produto por nome"
                populateResult={getProductsHandler}
                query={query}
                value={query.search}
              />
            </Col>
            {catalogPermission && (
              <Col>
                <Dropdown
                  overlay={actions}
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <Button
                    size="large"
                    className="ant-btn-secondary"
                    style={{ padding: '0 8px' }}
                  >
                    automações
                  </Button>
                </Dropdown>
              </Col>
            )}
          </Row>
          <Row style={{ marginBottom: 16 }}>
            <Form style={{ display: 'flex', gap: 8 }}>
              <Row gutter={8} style={{ flexGrow: 1 }}>
                <Col span={6}>
                  <Select
                    size="large"
                    placeholder="Hubs"
                    mode="multiple"
                    value={hubFilter}
                    onChange={value => handleProductsFilter('hubs', value)}
                    filterOption={handleSelectSearch}
                    onFocus={populateHubs}
                    notFoundContent={hubsLoading ? <Spin size="small" /> : null}
                    loading={hubsLoading}
                    allowClear
                  >
                    {renderDropdownItens('hubs', hubs)}
                  </Select>
                </Col>
                <Col span={6}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('status')(
                      <Select
                        size="large"
                        placeholder="Status"
                        onChange={value =>
                          handleProductsFilter('status', value)
                        }
                        style={{ width: '100%' }}
                        allowClear
                      >
                        <Option value="active">Ativos</Option>
                        <Option value="disabled">Inativos</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('manufacturer')(
                      <Select
                        size="large"
                        placeholder="Marca"
                        onChange={value =>
                          handleProductsFilter('manufacturer', value)
                        }
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={handleSelectSearch}
                        onFocus={populateManufacturers}
                        notFoundContent={
                          manufacturersLoading ? <Spin size="small" /> : null
                        }
                        loading={manufacturersLoading}
                        allowClear
                      >
                        {renderDropdownItens('marcas', manufacturers)}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('category')(
                      <Select
                        size="large"
                        placeholder="Categoria"
                        onChange={value =>
                          handleProductsFilter('category', value)
                        }
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={handleSelectSearch}
                        onFocus={populateCategories}
                        notFoundContent={
                          categoriesLoading || departmentsLoading ? (
                            <Spin size="small" />
                          ) : null
                        }
                        loading={categoriesLoading || departmentsLoading}
                        allowClear
                      >
                        {departments.map(item => {
                          const categoriesArr = categories
                            .filter(
                              obj =>
                                obj.attributes.department_id === Number(item.id)
                            )
                            .sort((a, b) =>
                              a.attributes.name.localeCompare(b.attributes.name)
                            );
                          return (
                            <OptGroup
                              label={item.attributes.name}
                              key={`department-${item.id}`}
                            >
                              {categoriesArr?.map(obj => {
                                return (
                                  <Option
                                    key={obj.attributes.id}
                                    value={obj.attributes.id}
                                  >
                                    {obj.attributes.name} para{' '}
                                    {item.attributes.name}
                                  </Option>
                                );
                              })}
                            </OptGroup>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <div>
                <FilterMenu filterContent="products" />
              </div>
            </Form>
          </Row>
          {filteredQuery.filters &&
            Object.keys(filteredQuery.filters).length > 0 && (
              <FilteredTags
                query={filteredQuery}
                populateTable={getProductsHandler}
                setSelectedFilters={() => true}
                noMargin
              />
            )}
        </div>
        <Tables
          data={tableData}
          className="even-gray"
          count={count}
          showCountFilters
          showCountText="produtos"
          visiblePaginationUp
          visiblePaginationDown
          contentToShow="products"
          history={history}
          query={query}
          isLoading={isLoading}
          populateTables={getProductsHandler}
        />
      </div>
    </>
  );
};

ProductsPage.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    setFieldsValue: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired
};

const ProductsPageForm = Form.create({ name: 'ProductsPage' })(ProductsPage);

export default ProductsPageForm;
