import { takeEvery, put, call } from 'redux-saga/effects';
import normalize from 'json-api-normalizer';
import { errorAlert, successAlert } from '@/src/utils/notifications';

import {
  setIsLoading,
  setEtas,
  setEtaSingle,
  getEtas,
  getEtaSingle,
  getUpdateEta,
  getCreateEta,
  getDeleteEta,
  importCSVEtaBatchData,
  exportCSVEtaBatchData
} from '@/src/store/modules/etas/slice';

import {
  getEtasData,
  getEtaSingleData,
  setUpdateEtaData,
  setCreateEtaData,
  setDeleteEtaData
} from '@/src/api/etas';

import { importCSVEtaBatchData as importCSVEtaBatchDataRequest } from '@/src/api/import-file';
import { exportCSVEtaBatchData as exportCSVEtaBatchDataRequest } from '@/src/api/export-file';

function* setEtasSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getEtasData, payload);
    const normalized = normalize(response.data, { endpoint: '/etaSchedules' });
    yield put(setEtas(normalized));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* setEtaSingleSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(getEtaSingleData, payload);
    yield put(setEtaSingle(response.data.data));
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* setUpdateEtaSaga({ payload }) {
  try {
    const response = yield call(setUpdateEtaData, payload);
    yield put(setEtaSingle(response.data.data));
    successAlert('Os dados do ETA foram atualizados');
  } catch (err) {
    errorAlert(err);
  }
}

function* setCreateEtaSaga({ payload }) {
  try {
    const response = yield call(setCreateEtaData, payload);
    yield put(setEtaSingle(response.data.data));
    successAlert('Novo ETA cadastrado');
  } catch (err) {
    errorAlert(err);
  }
}

function* setDeleteEtaSaga({ payload }) {
  try {
    yield call(setDeleteEtaData, payload);
    successAlert('ETA removido');
  } catch (err) {
    errorAlert(err);
  }
}

function* importCSVEtaBatchSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const { file } = payload;
    const data = new FormData();
    data.append('file', file[0]);

    yield call(importCSVEtaBatchDataRequest, data);
    successAlert('Arquivo importado com sucesso!');

    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}
function* exportCSVEtaBatchSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(exportCSVEtaBatchDataRequest, payload);

    const byteCharacters = atob(response.data);

    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }

    const blob = new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const blobURL = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobURL;
    link.download = `${blobURL.slice(27)}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
}

function* etasListSaga() {
  yield takeEvery(getEtas, setEtasSaga);
  yield takeEvery(getEtaSingle, setEtaSingleSaga);
  yield takeEvery(getUpdateEta, setUpdateEtaSaga);
  yield takeEvery(getCreateEta, setCreateEtaSaga);
  yield takeEvery(getDeleteEta, setDeleteEtaSaga);
  yield takeEvery(importCSVEtaBatchData, importCSVEtaBatchSaga);
  yield takeEvery(exportCSVEtaBatchData, exportCSVEtaBatchSaga);
}

export default etasListSaga;
