import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  recommendations: [],
  count: 0,
  recommendation: {},
  query: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '[id]=asc',
    url: '',
    filters: {}
  },
  spotlightSelect: [],
  spotlightLoading: false,
  isLoading: false
};

const recommendationsSlice = createSlice({
  name: '@recommendation',
  initialState,
  reducers: {
    setRecommendations(prevState, action) {
      const state = prevState;
      state.recommendations = action.payload.recommendations;
      state.count = action.payload.count;

      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        search: action.payload.query.search || '',
        sort: action.payload.query.sort || '',
        filters: action.payload.query.filters || {},

        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }${
          action.payload.query?.filters?.by_recommType
            ? `&type=${action.payload.query?.filters.by_recommType}`
            : ''
        }${`&sort${action.payload.query.sort}`}`
      };

      state.isLoading = false;
      return state;
    },
    setRecommendation(prevState, action) {
      const state = prevState;
      state.recommendation = action.payload;

      state.isLoading = false;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    setSpotlightSelect(prevState, action) {
      const state = prevState;
      state.spotlightSelect = action.payload;
      return state;
    },
    setSpotlightLoading(prevState, action) {
      const state = prevState;
      state.spotlightLoading = action.payload;
      return state;
    },
    getRecommendations(state) {
      return state;
    },
    getRecommendation(state) {
      return state;
    },
    getCreateRecommendation(state) {
      return state;
    },
    getUpdateRecommendation(state) {
      return state;
    },
    getSpotlightSelect(state) {
      return state;
    },
    importCSVRecommendations(state) {
      return state;
    }
  }
});

export const {
  setRecommendations,
  getRecommendations,
  setRecommendation,
  getRecommendation,
  setIsLoading,
  setPagination,
  setSort,
  getCreateRecommendation,
  getUpdateRecommendation,
  setSpotlightSelect,
  getSpotlightSelect,
  setSpotlightLoading,
  importCSVRecommendations
} = recommendationsSlice.actions;

export default recommendationsSlice.reducer;
