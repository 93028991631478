export const getOrderQueryUrlState = state => state.orders.query.url;
export const getCustomersQueryUrlState = state => state.customers.query.url;
export const getAislesQueryUrlState = state => state.aisles.query.url;
export const getHighlightsQueryUrlState = state => state.highlights.query.url;
export const getSkusPetzQueryUrlState = state => state.skusPetz.query.url;
export const getAssemblingsQueryUrlState = state => state.assemblings.query.url;
export const getAssemblingsQueryState = state => state.assemblings.query;
export const getSubscriptionUrlQuery = state => state.subscriptions.query.url;

export const getPromotionsQueryUrlState = state => state.promotions.query.url;
export const getPromotionsInState = state => state.promotions.promotions;
export const getPromotionId = state => state.promotion.promotion.id;

export const getRouterState = state => state.router;

export const getOrderStatusState = state => state.order.orderStatus;
export const getOrderstate = state => state.order.order;
export const getOrderTimelineState = state => state.order.orderTimeline;

export const getDelivererState = state => state.order.deliverer;

export const getCouponsState = state => state.coupons.coupons;

export const getReferralsState = state => state.referrals.referrals;
export const getReferralsQuery = state => state.referrals.query;
export const getReferralsTotal = state => state.referrals.total;

export const getTimelineEvents = state => state.timeline.timelineEvents;
export const getOrderTimeline = state => state.timeline.orderTimeline;

export const getProductsAisle = state => state.aisles.products;
export const getAddedProductsAisle = state => state.aisles.addedProducts;

export const getAttributesOnEditDepartment = state =>
  state.catalogDepartments.editDepartment;

export const getDepartmentAttributes = state =>
  state.catalogDepartments.attributes;
export const getAllCategoriesQueryUrlState = state =>
  state.catalogCategories.query.url;

export const getAttributesCategory = state =>
  state.catalogCategories.attributes;

export const getAttributesQueryUrlState = state => state.attributes.query.url;

export const getDepartmentsQueryUrlState = state => state.departments.query.url;

export const getProductsQueryUrlState = state => state.allProducts.query.url;
export const getProductsQueryState = state => state.allProducts.query;

export const getManufacturersQueryUrlState = state =>
  state.manufacturers.query.url;

export const getAttributesDepartment = state =>
  state.catalogDepartments.attributes;

export const getExpeditionWaitingOrdersState = state =>
  state.expedition.waitingOrders;
export const getExpeditionViewerState = state => state.expedition.viewer;
export const getExpeditionOrdersState = state => state.expedition.orders;
export const getExpeditionUserOrdersState = state =>
  state.expedition.userOrders;
export const getExpeditionHubsState = state => state.expedition.hubsFilter;
export const getExpeditionQueryState = state => state.expedition.query;
export const getExpeditionReloadState = state => state.expedition.reloadOrders;

export const getPoliciesQueryUrlState = state => state.policies.query.url;
export const getPriceTablesQueryUrlState = state =>
  state.priceTables.priceTablesQuery.url;
export const getPriceIndexingUrlState = state =>
  state.priceIndexing.priceIndexingQuery.url;

export const getRecommendationsQueryUrlState = state =>
  state.recommendations.query.url;
export const getRecommendationsQueryState = state =>
  state.recommendations.query;

export const getDeliverersQueryUrlState = state => state.deliverers.query.url;

export const getAccountIdState = state => state.account.account.id;

export const getBreedsQueryState = state => state.breeds.query;
export const getAccessoriesQueryState = state => state.accessories.query;

export const getGatewayStoresQueryState = state => state.gatewayStores.query;

export const getCustomerIdState = state => state.customers.customer.id;

export const getProductIdState = state => state.productDetails.product.id;

export const getAttributes = state => state.attributes.attributes;
