import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Dropdown, Menu } from 'antd';

import OrdersTable from '@/src/components/Orders/OrdersTable/index';
import Searchbar from '@/src/components/Searchbar/Searchbar';
import FilteredTags from '@/src/components/Filters/FilteredTags/index';
// import BookmarksNav from '@/src/components/Filters/BookmarksNav/index';
// import SaveFilters from '@/src/components/Filters/SaveFilters/index';
import FilterMenu from '@/src/pages/Filters/index';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';
import buildJsonData from '@/src/utils/buildJsonData';

import {
  getOrders,
  setSort,
  setPagination,
  goToOrder,
  setSelectedFilters,
  exportCSV
} from '@/src/store/modules/orders/slice';

// import {
//   saveAccountBookmark,
//   removeAccountBookmark
// } from '@/src/store/modules/account/slice';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';
import { ZEENOW_STORE } from '@/src/globals/constants';

import ModalContent from './ModalCSV';

export const OrdersPage = () => {
  const [orders, setOrders] = useState([]);

  const [showCSVModal, setShowCSVModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { editPermission } = usePermissions();

  const ordersPermission = editPermission(modules.ORDERS);

  const getOrdersHandler = useCallback(
    urlQuery => {
      dispatch(getOrders({ ...urlQuery }));
    },
    [dispatch]
  );

  const setPaginationHandler = useCallback(
    payload => {
      dispatch(setPagination(payload));
    },
    [dispatch]
  );
  const setSortHandler = useCallback(
    payload => {
      dispatch(setSort(payload));
    },
    [dispatch]
  );
  const goToOrderHandler = useCallback(
    payload => {
      dispatch(goToOrder(payload));
    },
    [dispatch]
  );
  // const saveBookmarkHandler = useCallback(
  //   payload => {
  //     dispatch(saveAccountBookmark(payload));
  //   },
  //   [dispatch]
  // );
  // const removeBookmarkHandler = useCallback(
  //   payload => {
  //     dispatch(removeAccountBookmark(payload));
  //   },
  //   [dispatch]
  // );
  const setSelectedFiltersHandler = useCallback(
    payload => {
      dispatch(setSelectedFilters(payload));
    },
    [dispatch]
  );

  const exportCSVHandler = () => {
    const params = buildQueryFromUrl(history);
    dispatch(exportCSV({ query: params }));
  };

  useEffect(() => {
    const params = buildQueryFromUrl(history, {
      daysAgo: '1',
      filters: {
        by_hub: [],
        by_status: [],
        by_deliverer: [],
        by_coupons: [],
        by_order_request_origin: []
      }
    });
    getOrdersHandler({ query: params });
  }, [getOrdersHandler, history]);

  const {
    isLoading,
    orders: ordersData,
    count,
    total,
    query,
    selectedHubs,
    selectedCoupons,
    selectedDeliverers
    // selectedFilters
  } = useSelector(state => state.orders);
  // const { bookmarks, loadingBookmarks } = useSelector(state => state.account);

  // const ordersBookmarks = bookmarks.filter(
  //   bookmark => bookmark.attributes.scope === 'Orders'
  // );

  useEffect(() => {
    const ordersArr = buildJsonData(ordersData, 'order');
    // console.log(ordersArr);
    setOrders(ordersArr);
  }, [ordersData]);

  const actions = (
    <Menu>
      <Menu.Item key="export-csv" onClick={exportCSVHandler}>
        Exportar pedidos
      </Menu.Item>
      <Menu.Item key="import-keywords" onClick={() => setShowCSVModal(true)}>
        Importar pedidos atrasados
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <ModalContent
        showCSVModal={showCSVModal}
        setShowCSVModal={setShowCSVModal}
      />

      <Row type="flex" justify="space-between" align="middle" className="mb-20">
        <Col span={20}>
          <h1 className="m-0">Pedidos</h1>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '8px 16px 16px'
        }}
      >
        {/* <Row>
          <Col span={24}>
            <BookmarksNav
              scope="Orders"
              accountBookmarks={ordersBookmarks}
              populateItems={getOrdersHandler}
              removeAccountBookmark={removeBookmarkHandler}
              query={query}
              saveAccountBookmark={saveBookmarkHandler}
              selectedFilters={selectedFilters}
            />
          </Col>
        </Row> */}
        <Row type="flex" justify="space-between" gutter={[8, 8]}>
          <Col span={16}>
            <Searchbar
              placeholder="Buscar por CPF, número do pedido, nome ou e-mail do cliente"
              populateResult={getOrdersHandler}
              query={query}
              value={query.search}
            />
          </Col>
          <Col>
            <FilterMenu filterContent="orders" />
            {ZEENOW_STORE ? (
              <Dropdown
                overlay={actions}
                trigger={['click']}
                placement="bottomRight"
                disabled={!ordersPermission}
              >
                <Button
                  size="large"
                  className="ant-btn-secondary"
                  style={{ padding: '0 8px' }}
                >
                  automações
                </Button>
              </Dropdown>
            ) : (
              <Button
                onClick={exportCSVHandler}
                size="large"
                className="ant-btn-secondary"
                disabled={!ordersPermission}
              >
                exportar csv
              </Button>
            )}
          </Col>
        </Row>
        <Row type="flex">
          {/* {selectedFilters ? (
            <Col>
              <section className="mt-20 mb-20">
                <SaveFilters
                  scope="Orders"
                  accountBookmarks={bookmarks}
                  query={query}
                  loadingBookmarks={loadingBookmarks}
                  // saveAccountBookmark={saveBookmarkHandler}
                />
              </section>
            </Col>
          ) : null} */}
          <Col>
            <section className="mt-20 mb-20">
              <FilteredTags
                selectedHubs={selectedHubs}
                selectedDeliverers={selectedDeliverers}
                selectedCoupons={selectedCoupons}
                query={query}
                populateTable={getOrdersHandler}
                setSelectedFilters={setSelectedFiltersHandler}
              />
            </section>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <OrdersTable
              isLoading={isLoading}
              className="even-gray"
              history={history}
              orders={orders}
              count={count}
              total={total}
              query={query}
              setPaginationState={setPaginationHandler}
              setSortState={setSortHandler}
              populateOrdersTable={getOrdersHandler}
              goToOrder={goToOrderHandler}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OrdersPage;
