import { takeEvery, put, call } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  getPromotion as getPromotionRequest,
  updatePromotion as updatePromotionRequest
} from '@/src/api/promotions';
import {
  setIsPromotionLoading,
  setPromotion,
  getPromotion
} from '@/src/store/modules/promotion-details/slice';

import { setTags } from '@/src/store/modules/tags/slice';

import { errorAlert } from '@/src/utils/notifications';

function* getUpdatePromotionDetailsSaga({ payload }) {
  const endpoint =
    payload.type === 'get' ? getPromotionRequest : updatePromotionRequest;
  try {
    yield put(setIsPromotionLoading(true));
    console.log(payload);
    const response = yield call(endpoint, payload);
    let promotion = response.data.data.attributes;
    const tag =
      response.data.included?.filter(included => included.type === 'tag') || [];
    const tags = tag.map(tagName => tagName.attributes.name);

    const marketingTag =
      response.data.included?.filter(
        included => included.type === 'marketing_tag'
      ) || [];
    const marketingTags = marketingTag?.map(mtag => mtag?.attributes);

    promotion = { ...promotion, mtags: marketingTags };
    // yield put(setPromoMTags(marketingTags));
    yield put(setTags(tags));
    // yield put(setPromotion({ promotion }));
    yield put(setPromotion({ promotion }));
    if (payload.type !== 'get')
      notification.success({
        message: 'Sucesso!',
        duration: 4,
        className: 'success',
        description: 'A promoção foi atualizada.'
      });
  } catch (err) {
    yield put(setIsPromotionLoading(false));
    errorAlert(err);
  }
}

function* promotionDetailsSaga() {
  yield takeEvery(getPromotion, getUpdatePromotionDetailsSaga);
}

export default promotionDetailsSaga;
