import React from 'react';
import { Row, Col, Card, /* Button, */ Checkbox /* Icon */ } from 'antd';
import PropTypes from 'prop-types';

const Addresses = props => {
  const { addresses, editPermission } = props;

  return (
    <Card
      className="no-shadow"
      title={<h2>Endereços</h2>}
      // extra={<Button type="primary">Adicionar endereço</Button>}
    >
      {addresses.length > 0 ? (
        <Row gutter={[8, 8]} type="flex" className="car-addresses">
          {addresses.map(address => (
            <Card
              key={address.id}
              style={{ width: '250px', height: 'auto' }}
              span={12}
            >
              <Row type="flex">
                <Col span={4}>
                  <Checkbox
                    checked={address.attributes.selected}
                    disabled={!address.attributes.selected || !editPermission}
                    className="customer-info-checkbox"
                  />
                </Col>
                <Col span={18} offset={1}>
                  <p style={{ marginBottom: 0 }}>
                    {address.attributes?.street}, {address.attributes?.number}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {address.attributes?.city
                      ? `${address.attributes.city} - `
                      : ''}
                    {address.attributes?.state
                      ? `${address.attributes.state} - `
                      : ''}
                    {address.attributes?.zipcode}
                  </p>
                  {address.attributes.hub_name && (
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      {address.attributes.hub_name}
                    </p>
                  )}
                </Col>
                {/* <Col span={2}>
                  <Button type="link" style={{ color: '#737373', padding: 0 }}>
                    <Icon type="edit" theme="filled" />
                  </Button>
                </Col> */}
              </Row>
            </Card>
          ))}
        </Row>
      ) : (
        <p>Cliente não tem endereços cadastrados</p>
      )}
    </Card>
  );
};

Addresses.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        id: PropTypes.number,
        customer_id: PropTypes.number,
        nickname: PropTypes.string,
        selected: PropTypes.bool,
        street: PropTypes.string,
        number: PropTypes.string,
        complement: PropTypes.string,
        zipcode: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        latitude: PropTypes.string,
        longitude: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        place_id: PropTypes.string,
        hub_id: PropTypes.number,
        active: PropTypes.boll
      })
    })
  ).isRequired,
  editPermission: PropTypes.bool.isRequired
};

export default Addresses;
