import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Icon, Input, Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MaskedInput from 'antd-mask-input';

import Tables from '@/src/components/Tables';
import ModalCoverage from '@/src/components/Hubs/ModalCoverage';

import { getRegions } from '@/src/store/modules/regions/slice';
import { getUpdateHub } from '@/src/store/modules/hubs-data/slice';

import {
  getCreateSuspension,
  getUpdateSuspension,
  getDeleteSuspension
} from '@/src/store/modules/suspensions/slice';

import Edit from '@/src/assets/images/edit.svg';
import Delete from '@/src/assets/images/delete.svg';

import styles from './HubsSuspension.module.scss';

const HubsSuspension = ({ hubData, form, suspensionPermission }) => {
  const dispatch = useDispatch();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalCancelVisibility, setModalCancelVisibility] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalType, setModalType] = useState('adicionar');
  const [tableData, setTableData] = useState([]);
  const [suspensionDelete, setSuspensionDelete] = useState();

  const { getFieldDecorator, setFieldsValue, validateFields } = form;

  const { id } = useParams();

  const { list: regions, isLoading } = useSelector(state => state.regions);
  const { suspension } = useSelector(state => state.suspensions);

  const queryDefault = {
    include: 'suspensions',
    filters: [
      {
        key: 'hub_id',
        type: 'eq',
        value: id
      }
    ]
  };

  useEffect(() => {
    dispatch(getRegions({ query: { ...queryDefault } }));
    // eslint-disable-next-line
  }, [dispatch, id, suspension]);

  useEffect(() => {
    const {
      suspendedSince,
      suspendedUntil,
      suspensionTitle,
      suspensionDescription
    } = hubData;
    setFieldsValue({
      openAt: suspendedSince || '',
      closeAt: suspendedUntil || '',
      suspendedTitle: suspensionTitle || '',
      suspendedDesc: suspensionDescription || ''
    });
    // eslint-disable-next-line
  }, [hubData]);

  const getSuspensionListHandler = useCallback(
    payload => {
      dispatch(getRegions({ ...payload }));
    },
    [dispatch]
  );

  const changeTableValue = data => {
    setModalData(data);
    setModalType('editar');
    setModalVisibility(true);
  };

  const deleteTableValue = suspensionId => {
    setSuspensionDelete(suspensionId);
    setModalCancelVisibility(true);
  };

  const setRegionTableData = value => {
    const schedules = [];
    const regionsObj = value || regions;
    regionsObj.forEach(region => {
      return region?.suspensions?.forEach((schedule, index) => {
        schedules.push({
          id: index /* ID for render in table */,
          regionID: region.id,
          name: region.name,
          changeValue: changeTableValue,
          deleteValue: () => deleteTableValue(schedule.id),
          schedule
        });
      });
    });
    return schedules;
  };

  useEffect(() => {
    const data = setRegionTableData();
    setTableData(data);
    // console.log(data);
    // eslint-disable-next-line
  }, [dispatch, regions]);

  const newTableValue = () => {
    setModalData({});
    setModalType('adicionar');
    setModalVisibility(!modalVisibility);
  };

  const renderInput = (title, value, mask) => {
    let content;
    let colSpan;
    if (mask) {
      colSpan = 4;
      content = getFieldDecorator(
        value,
        {}
      )(
        <MaskedInput
          mask={mask}
          name={title}
          disabled={!suspensionPermission}
        />
      );
    } else if (value === 'suspendedDesc') {
      colSpan = 8;
      content = getFieldDecorator(
        value,
        {}
      )(
        <Input.TextArea
          name={title}
          disabled={!suspensionPermission}
          rows={1}
          style={{ marginBottom: 0 }}
        />
      );
    } else {
      colSpan = 5;
      content = getFieldDecorator(
        value,
        {}
      )(<Input name={title} disabled={!suspensionPermission} />);
    }
    return (
      <Col span={colSpan} className={styles.defaultSupension__input}>
        <span>{title}</span>
        {content}
      </Col>
    );
  };

  const onSubmit = evt => {
    evt.preventDefault();

    validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!err) {
        const data = {
          data: {
            type: 'hubs',
            id,
            attributes: {
              suspended_since: values.openAt,
              suspended_until: values.closeAt,
              suspension_title: values.suspendedTitle,
              suspension_description: values.suspendedDesc
            }
          }
        };

        dispatch(getUpdateHub(data));
      }
    });
  };

  const handleModalVisibility = () => setModalVisibility(!modalVisibility);

  const submitData = values => {
    const data = {
      data: {
        type: 'suspensions',
        relationships: {
          region: {
            data: {
              type: 'regions',
              method: 'update'
            }
          }
        }
      }
    };

    if (modalType === 'editar') {
      dispatch(
        getUpdateSuspension({
          data: {
            ...data.data,
            id: values[0].id,
            attributes: {
              suspended_since: values[0].suspended_since,
              suspended_until: values[0].suspended_until,
              suspension_title: values[0].title,
              suspension_description: values[0].description
            },
            relationships: {
              region: {
                data: {
                  ...data.data.relationships.region.data,
                  id: values[0].region
                }
              }
            }
          }
        })
      );
    } else {
      values.forEach(item => {
        dispatch(
          getCreateSuspension({
            data: {
              ...data.data,
              attributes: {
                suspended_since: item.suspended_since,
                suspended_until: item.suspended_until,
                suspension_title: item.title,
                suspension_description: item.description
              },
              relationships: {
                region: {
                  data: {
                    ...data.data.relationships.region.data,
                    id: item.region
                  }
                }
              }
            }
          })
        );
      });
    }

    return setModalVisibility(false);
  };

  const handleDeleteValue = () => {
    setModalCancelVisibility(false);
    dispatch(getDeleteSuspension(suspensionDelete));

    setTimeout(() => {
      getSuspensionListHandler({ query: { ...queryDefault } });
    }, 1000);
  };

  const columns = [
    {
      title: 'Região',
      align: 'left',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: 'Título',
      align: 'left',
      dataIndex: 'schedule.suspensionTitle'
    },
    {
      title: 'Descrição',
      align: 'left',
      dataIndex: 'schedule.suspensionDescription'
    },
    {
      title: 'A partir de',
      align: 'left',
      key: 'schedule.suspended_since',
      render: data => <>{data.schedule.suspendedSince}</>,
      sorter: (a, b) =>
        Number(a.schedule.suspendedSince.replace(':', '')) -
        Number(b.schedule.suspendedSince.replace(':', ''))
    },
    {
      title: 'Até as',
      align: 'left',
      colSpan: 2,
      key: 'schedule.suspended_until',
      render: data => <>{data.schedule.suspendedUntil}</>,
      sorter: (a, b) =>
        Number(a.schedule.suspendedUntil.replace(':', '')) -
        Number(b.schedule.suspendedUntil.replace(':', ''))
    },
    {
      title: '',
      align: 'right',
      key: 'actions',
      colSpan: 0,
      render: data => {
        if (!suspensionPermission) return null;
        const {
          suspensionTitle,
          suspensionDescription,
          suspendedSince,
          suspendedUntil
        } = data?.schedule;

        const editData = () => {
          const inputValues = {
            id: data?.schedule.id,
            region: data?.name,
            regionID: data?.regionID,
            suspensionTitle,
            suspensionDescription,
            suspendedSince,
            suspendedUntil
          };
          data.changeValue(inputValues);
        };

        return (
          <>
            <button
              type="button"
              className="tableEditDelete__edit"
              onClick={editData}
            >
              <img src={Edit} alt="Editar" />
            </button>
            <button
              type="button"
              className="tableEditDelete__delete"
              onClick={() => data?.deleteValue(id)}
            >
              <img src={Delete} alt="Deletar" />
            </button>
          </>
        );
      }
    }
  ];

  return (
    <div className={styles.hubsSuspension}>
      <ModalCoverage
        type={modalType}
        handleVisibility={handleModalVisibility}
        visibility={modalVisibility}
        data={modalData}
        regions={regions}
        content="suspensions"
        submitData={submitData}
      />
      <Modal
        width={400}
        onCancel={() => setModalCancelVisibility(false)}
        visible={modalCancelVisibility}
        onOk={handleDeleteValue}
        className={`${styles.modalDelete} modalCenteredButtons`}
      >
        <h2 className={styles.modalDelete__title}>Remover suspensão</h2>
        <p className={styles.modalDelete__text}>
          Deseja realmente remover a suspensão?
        </p>
      </Modal>
      <Row
        className={`
        ${styles.coverageWrapper}
        ${styles.sectionColumn}`}
      >
        <h3>Definir Suspensão do Hub</h3>
        <p>Edição de suspensão definitiva no hub.</p>
        <Form onSubmit={onSubmit} className={styles.defaultSupension}>
          <Input type="hidden" value={id} />
          <Row
            gutter={8}
            type="flex"
            style={{ marginBottom: 14 }}
            align="bottom"
          >
            {renderInput('Título', 'suspendedTitle', '')}
            {renderInput('Suspender de', 'openAt', '11:11')}
            {renderInput('Suspender até', 'closeAt', '11:11')}
            {renderInput('Descrição', 'suspendedDesc', '')}
            {suspensionPermission && (
              <Col span={3}>
                <Button
                  className="ant-btn ant-btn-primary"
                  onClick={onSubmit}
                  style={{ float: 'right' }}
                >
                  Salvar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Row>
      <Row className={`${styles.sectionColumn} ${styles.suspensionTable}`}>
        <Row>
          <h3>Definir Suspensão da Região</h3>
          <p>
            Suspensão de regiões são temporárias e expiram de acordo com o
            horário selecionado
          </p>
          {suspensionPermission && (
            <button
              type="button"
              className={styles.buttonModalCreate}
              onClick={newTableValue}
              aria-label="Adicionar suspensão"
            >
              <Icon
                type="plus-circle"
                style={{ fontSize: '30px', color: '#333', display: 'block' }}
              />
            </button>
          )}
        </Row>
        <Row>
          <Tables
            data={tableData}
            query={queryDefault}
            populateTables={getSuspensionListHandler}
            className="even-gray"
            rowKey={record => record.schedule.id}
            // contentToShow="hubsSuspension"
            isLoading={isLoading}
            columnsDefaults={columns}
          />
        </Row>
      </Row>
    </div>
  );
};

HubsSuspension.defaultProps = {
  hubData: {},
  form: {
    getFieldDecorator: () => {},
    validateFields: () => {},
    setFieldsValue: () => {}
  }
};

HubsSuspension.propTypes = {
  hubData: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }),
  suspensionPermission: PropTypes.bool.isRequired
};

const WrappedHubsSuspension = Form.create({ name: 'NewSuspensionForm' })(
  HubsSuspension
);

export default WrappedHubsSuspension;
