import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input,
  Modal,
  Row,
  Col,
  Form,
  Select,
  Icon,
  Tooltip,
  Popover,
  Radio
} from 'antd';
import disclaimerImg from '@/src/assets/images/product/image-metadata-disclaimer.jpg';
import validateJson from '@/src/utils/validateJson';
import productVideos from '@/src/globals/productVideos';
import { handleSelectSearch } from '@/src/utils/searchUtils';

const MetadataModal = props => {
  const {
    image,
    setImage,
    handleImageMetadata,
    handleImageError,
    form,
    allImages
  } = props;

  const { product } = useSelector(state => state.productDetails);

  const [hasDisclaimer, setHasDisclaimer] = useState(false);
  const [hasSku, setHasSku] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);

  const handleCloseModal = () => {
    setImage({});
  };

  const handleSubmit = async () => {
    try {
      const formValues = await form.validateFields();

      const filtered = Object.fromEntries(
        Object.entries(formValues).filter(
          ([_, value]) => value !== undefined && value !== ''
        )
      );
      const jsonString = JSON.stringify(filtered);

      handleImageMetadata(image, jsonString);
      handleCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (image && validateJson(image.metadata)) {
      const metadata = JSON.parse(image.metadata);
      form.setFieldsValue(metadata);
      setHasDisclaimer(!!metadata.disclaimer);
      setHasSku(!!metadata.image_on_sku);
      if (metadata.video) {
        const listedVideo = productVideos
          .filter(item => item.default)
          .find(item => item.url === metadata.video);
        setVideoUrl(!listedVideo);
      }
    } else {
      setHasDisclaimer(false);
      setHasSku(false);
    }
    // eslint-disable-next-line
  }, [image]);

  return (
    <Modal
      title="ATRIBUTOS DE IMAGEM"
      okText="Salvar"
      visible={!!image.url}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
      className="ant-modal-hubs centered-footer"
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      destroyOnClose
      centered
      width={900}
    >
      <Form>
        <Row gutter={24} type="flex">
          <Col span={12}>
            <Form.Item>
              <img
                style={{ maxWidth: '100%', height: 'auto' }}
                src={image.url}
                alt="preview"
                onError={handleImageError}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row style={{ marginBottom: 12 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div>
                  <h3 style={{ marginBottom: 0 }}>Vídeo</h3>
                  <span>(opcional)</span>
                  <Tooltip
                    title="Caso não encontre o vídeo desejado, solicite o link ao time de desenvolvimento e adicione como URL"
                    placement="right"
                  >
                    <Icon style={{ margin: '0 6px' }} type="info-circle" />
                  </Tooltip>
                </div>

                <Radio.Group
                  value={videoUrl ? 'url' : 'list'}
                  onChange={e => {
                    setVideoUrl(e.target.value === 'url');
                    form.setFieldsValue({ video: undefined });
                  }}
                  buttonStyle="solid"
                  size="small"
                >
                  <Radio.Button value="list">Lista</Radio.Button>
                  <Radio.Button value="url">URL</Radio.Button>
                </Radio.Group>
              </div>

              <Form.Item style={{ marginBottom: 0 }}>
                {form.getFieldDecorator(
                  'video',
                  {}
                )(
                  videoUrl ? (
                    <Input placeholder="URL do vídeo" />
                  ) : (
                    <Select
                      allowClear
                      showSearch
                      filterOption={handleSelectSearch}
                      placeholder="Selecione uma categoria ou produto"
                      dropdownMatchSelectWidth={false}
                    >
                      {productVideos
                        .filter(item => item.default)
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map(item => (
                          <Select.Option value={item.url} key={item.url}>
                            <img
                              src={item.poster}
                              alt="Pôster do vídeo"
                              style={{
                                marginRight: 8,
                                width: 60,
                                height: 60
                              }}
                            />
                            {item.title}
                          </Select.Option>
                        ))}
                    </Select>
                  )
                )}
              </Form.Item>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <div>
                <h3 style={{ marginBottom: 0 }}>Texto informativo</h3>
                <span>(opcional)</span>
                <Popover
                  placement="right"
                  content={
                    <img
                      src={disclaimerImg}
                      alt="Exemplo de texto informativo em imagem"
                      width={300}
                      height={300}
                    />
                  }
                >
                  <Icon style={{ marginLeft: 6 }} type="info-circle" />
                </Popover>
              </div>
              <Form.Item style={{ marginBottom: 0 }}>
                {form.getFieldDecorator(
                  'disclaimer',
                  {}
                )(
                  <Input
                    placeholder="Exemplo: Modelagem ampla"
                    allowClear
                    onChange={e => {
                      setHasDisclaimer(!!e.target.value);
                      if (!e.target.value) {
                        form.setFieldsValue({ position: undefined });
                      }
                    }}
                  />
                )}
              </Form.Item>
            </Row>
            <Row
              style={{
                marginBottom: 0,
                display: hasDisclaimer ? 'block' : 'none'
              }}
            >
              <h3 style={{ marginBottom: 8 }}>Posição do texto</h3>
              <Form.Item style={{ marginBottom: 0 }}>
                {form.getFieldDecorator('position', {
                  rules: [
                    {
                      required: !!form.getFieldValue('disclaimer'),
                      message:
                        'Campo obrigatório se o Texto informativo estiver preenchido'
                    }
                  ]
                })(
                  <Radio.Group>
                    <Radio style={{ width: 150 }} value="top-left">
                      Superior esquerdo
                    </Radio>
                    <Radio style={{ width: 150 }} value="top-right">
                      Superior direito
                    </Radio>
                    <Radio style={{ width: 150 }} value="bottom-left">
                      Inferior esquerdo
                    </Radio>
                    <Radio style={{ width: 150 }} value="bottom-right">
                      Inferior direito
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Row>
            {/* Atributo hover não é necessário, pois imagem fica separada em large_images */}
            {/* <Row style={{ marginBottom: 12 }} type="flex" align="middle">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ marginBottom: 0 }}>Hover</h3>
                <span>(opcional)</span>
                <Tooltip
                  title="Imagem exibida sobre outra ao passar o mouse. Exemplo: vitrine de produtos"
                  placement="right"
                >
                  <Icon style={{ margin: '0 6px' }} type="info-circle" />
                </Tooltip>
              </div>
              <Form.Item style={{ marginBottom: 0 }}>
                {form.getFieldDecorator('hover', { valuePropName: 'checked' })(
                  <Switch
                  // disabled={!customFieldsPermission}
                  />
                )}
              </Form.Item>
            </Row> */}
            <Row style={{ marginBottom: 12 }}>
              <h3 style={{ marginBottom: 0 }}>SKU</h3>
              <span>(opcional)</span>
              <Tooltip
                title="Imagem será exibida apenas para o SKU selecionado"
                placement="right"
              >
                <Icon style={{ marginLeft: 6 }} type="info-circle" />
              </Tooltip>
              <Form.Item style={{ marginBottom: 0 }}>
                {form.getFieldDecorator(
                  'image_on_sku',
                  {}
                )(
                  <Select
                    placeholder="Nome do SKU"
                    allowClear
                    onChange={value => {
                      setHasSku(!!value);
                      if (!value) {
                        form.setFieldsValue({ image_index_on_sku: undefined });
                      }
                    }}
                  >
                    {(product?.skus || []).map(sku => (
                      <Select.Option key={sku.id} value={sku.name}>
                        {sku.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Row>
            <Row
              style={{ marginBottom: 12, display: hasSku ? 'block' : 'none' }}
            >
              <h3 style={{ marginBottom: 8 }}>Imagem alterada por SKU</h3>
              <Form.Item style={{ marginBottom: 0 }}>
                {form.getFieldDecorator('image_index_on_sku', {
                  rules: [
                    {
                      required: !!form.getFieldValue('image_on_sku'),
                      message: 'Campo obrigatório se o SKU estiver preenchido'
                    }
                  ]
                })(
                  <Radio.Group
                    style={{
                      overflowX: 'scroll',
                      whiteSpace: 'nowrap',
                      width: '100%'
                    }}
                  >
                    {allImages.map((img, index) => (
                      <Radio.Button
                        key={img}
                        value={index}
                        className="multiple-preview-image"
                        style={{
                          padding: 0,
                          margin: 0,
                          marginLeft: index === 0 ? 0 : 8
                        }}
                        disabled={img === image.url}
                      >
                        <img src={img} alt={`Imagem na posição ${index}`} />
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                )}
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

MetadataModal.propTypes = {
  image: PropTypes.bool.isRequired,
  setImage: PropTypes.func.isRequired,
  handleImageMetadata: PropTypes.func.isRequired,
  handleImageError: PropTypes.func.isRequired,
  allImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired
};

const MetadataModalForm = Form.create({ name: 'image-metadata-form' })(
  MetadataModal
);

export default MetadataModalForm;
