import { apiPortal, apiDispatch } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const exportCSVCustomer = payload => {
  const getUrl = queryBuilder(payload, '/customers/exports');
  return apiPortal.get(getUrl);
};

export const exportCSVPromotions = payload => {
  const getUrl = queryBuilder(payload, '/promotions/exports');
  return apiPortal.get(getUrl);
};

export const exportCSVOrders = payload => {
  const getUrl = queryBuilder(payload, '/orders/exports');
  return apiPortal.get(getUrl);
};

export const exportCSVPetzSkus = payload => {
  // const getUrl = queryBuilder(payload, '/api/skus');
  // console.log(getUrl);
  // return apiPortal.get(getUrl);
  return [];
};

export const exportCSVCoupons = payload => {
  const getUrl = queryBuilder(
    payload,
    `/promotions/${payload.promoId}/coupons/exports`
  );
  return apiPortal.get(getUrl);
};

export const exportCSVProductsList = payload => {
  const getUrl = queryBuilder(payload, `/export_products`);
  return apiPortal.get(getUrl);
};

export const exportCSVProductsSort = () => {
  return apiPortal.get('/export_products_sort');
};

export const exportCSVAisle = payload => {
  let url;
  if (payload.type === 'edit') {
    url = `aisles/${payload.id}/export_products_sort`;
  } else {
    url = `export_aisles_sort?category_id=${payload.category}`;
  }
  return apiPortal.get(url);
};

export const exportCSVPriceTable = payload =>
  apiPortal.get(`/export_prices?price_table_id=${payload}`);

export const exportCSVAssemblings = () =>
  apiPortal.get('/assemblings/sorting_spreadsheet');

export const exportCSVAssemblingProducts = payload =>
  apiPortal.get(`/assemblings/${payload}/products_spreadsheet`);

export const exportCSVHubSkus = payload => {
  const query = payload.map(id => `hub_internal_ids[]=${id}`).join('&');
  return apiPortal.get(`/export_stocks?${query}`);
};

export const exportCSVEtaBatchData = payload =>
  apiDispatch.get('/exports/hubs', payload);
