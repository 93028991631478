import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Row, Col, Button, Upload, message } from 'antd';
import PropTypes from 'prop-types';

import { importCSVRecommendations } from '@/src/store/modules/recommendations/slice';

const ModalCSV = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();

  const [CSVFile, setCSVFile] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const conditions = ['.csv', '.xlsx', '.xls'];
  const checkCSVType = file => conditions.some(el => file.name.includes(el));

  const beforeUpload = file => {
    checkCSVType(file);
    if (!checkCSVType(file)) {
      message.error('Apenas arquivos CSV são aceitos!', 5);
      setBtnDisabled(true);
      return false;
    }
    setCSVFile([file]);
    setBtnDisabled(false);
    return false;
  };

  const removeFile = () => {
    setCSVFile([]);
    setBtnDisabled(showModal);
  };

  const handleImportCSV = useCallback(() => {
    dispatch(importCSVRecommendations({ csv: CSVFile }));
    setShowModal(false);
    setBtnDisabled(false);
    setCSVFile([]);
    // eslint-disable-next-line
  }, [CSVFile]);

  const contentModalCSV = (
    <>
      <Row>
        <Col style={{}}>
          <span style={{ fontSize: 14 }}>
            O arquivo de recomendações deve ser no formato CSV, com separador
            ponto e vírgula, e as colunas Produto Recomendado, Tipo e Produto em
            Destaque:
          </span>
          <ul style={{ paddingLeft: '20px', marginTop: '10px' }}>
            <li style={{ marginBottom: '5px' }}>
              <strong>
                A planilha deve ter uma linha por recomendação e a primeira
                linha deve, necessariamente, conter os títulos das colunas.
              </strong>
            </li>
            <li style={{ marginBottom: '5px' }}>
              Coluna 1: <strong>Identificador</strong> do produto que será
              recomendado;
            </li>
            <li style={{ marginBottom: '5px' }}>
              Coluna 2: Tipo de recomendação{' '}
              <strong>Conjunto, Upsell, Bundle 1 e Bundle 2</strong>;
            </li>
            <li style={{ marginBottom: '5px' }}>
              Coluna 3: <strong>Identificador</strong> do produto que ficará em
              destaque.
            </li>
            <li style={{ marginBottom: '5px' }}>
              Os identificadores podem ser o{' '}
              <strong> external_id (código Petz)</strong> do SKU ou
              <strong> external_reference</strong> do produto.
            </li>
          </ul>
          <span
            style={{ fontSize: '14px', color: '#d9534f', fontWeight: 'bold' }}
          >
            Certifique-se de que o arquivo está formatado corretamente antes de
            importar.
          </span>
        </Col>

        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 25,
            marginTop: 25
          }}
        >
          <Upload
            className="upload-csv-file-halls"
            beforeUpload={beforeUpload}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onRemove={removeFile}
            fileList={CSVFile}
          >
            <Button className="ant-btn-secondary-copy">Escolher Arquivo</Button>
          </Upload>
          {CSVFile.length === 0 && (
            <p style={{ marginBottom: 0, marginLeft: 10 }}>
              Nenhum arquivo selecionado.
            </p>
          )}
        </Col>
      </Row>
    </>
  );

  return (
    <Modal
      title="IMPORTAR RECOMENDAÇÕES"
      visible={showModal}
      className="modal-csv-import-export "
      onCancel={() => {
        setShowModal(false);
        setBtnDisabled(false);
        setCSVFile([]);
      }}
      footer={[
        <Button
          key="submit"
          onClick={handleImportCSV}
          className="ant-btn ant-btn-primary"
          disabled={btnDisabled}
        >
          importar
        </Button>
      ]}
    >
      {contentModalCSV}
    </Modal>
  );
};

ModalCSV.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

export default ModalCSV;
