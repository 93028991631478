import { apiPortal, apiDispatch } from '@/src/api/config';

const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};

const importCSVAllAisles = (type, id, data) => {
  let url;
  let response;
  if (type === 'edit') {
    url = `aisles/${id}/update_products_by_spreadsheet`;
    response = apiPortal.put(url, data, config);
  } else {
    url = `aisles_sort_by_spreadsheet`;
    response = apiPortal.put(url, data, config);
  }
  return response;
};

export const importCSVProductsSort = data => {
  return apiPortal.patch('/products_sort_by_spreadsheets', data, config);
};

export const importCSVOrderDelay = data => {
  return apiPortal.post('/orders/delay_notifications', data, config);
};

export const importCSVAssemblings = data =>
  apiPortal.post('/assemblings/sorting_spreadsheet', data, config);

export const importCSVAssemblingProducts = payload =>
  apiPortal.put(
    `/assemblings/${payload.id}/products_spreadsheet`,
    payload.data,
    config
  );

export const importBuyAndEarnPromotions = data =>
  apiPortal.post('/gifts/create_by_spreadsheet', data, config);

export const importKWFileSaga = data =>
  apiPortal.post('/products/keywords', data, config);

export const importCSVEtaBatchData = data =>
  apiDispatch.patch('/batches/hubs', data, config);

export const importCSVRecommendations = data =>
  apiPortal.post('/recommendations/create_by_spreadsheet', data, config);

export default importCSVAllAisles;
