import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Tooltip, Modal, Avatar } from 'antd';

import Tags from '@/src/components/Tags/index';

import Whatsapp from '@/src/assets/images/whatsapp.svg';
import Push from '@/src/assets/images/icone_push.svg';
import { ReactComponent as DogAvatar } from '@/src/assets/images/pets/dog.svg';
import { ReactComponent as CatAvatar } from '@/src/assets/images/pets/cat.svg';

import { ZEENOW_STORE } from '@/src/globals/constants';
import formatCurrency from '@/src/utils/formatCurrency';

import CustomerDetailsStyles from './CustomerDetails.module.scss';

const { confirm } = Modal;

export const getAge = dateString => {
  if (dateString.includes('0000')) {
    const month = parseInt(dateString.split('0000-')[1].split('-')[0], 10);
    const monthsArr = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ];
    return `Aniversário em ${monthsArr[month - 1]}`;
  }
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;
  const birthYear = parseInt(dateString.split('-')[0], 10);
  const birthMonth = parseInt(dateString.split('-')[1], 10);
  let petAge = todayYear - birthYear;
  let petMonth = 0;
  if (todayMonth > birthMonth) {
    petMonth = todayMonth - birthMonth;
  } else if (todayMonth < birthMonth) {
    petAge -= 1;
    petMonth = 12 - Math.abs(todayMonth - birthMonth);
  }
  let ageStr;
  if (petAge === 0) {
    ageStr = `${petMonth}${petMonth === 1 ? ' mês' : ' meses'}`;
  } else {
    ageStr = `${petAge} ano${petAge > 1 ? 's' : ''}${
      petMonth > 0
        ? ` e ${petMonth}${petMonth === 1 ? ' mês' : ' meses'}`
        : ' 🎂'
    }`;
  }
  return ageStr;
};

const CustomerDetails = props => {
  const {
    customer,
    pet,
    pushNotificationFunction,
    removeTags,
    addTags,
    tags,
    editPermission
  } = props;

  const mountPetsList = () => {
    if (pet.length > 0) {
      return (
        <ul>
          {pet.map(el => {
            const {
              id,
              attributes: {
                species,
                name,
                breed,
                gender,
                birthday,
                image,
                memorial
              }
            } = el;
            const formattedGender = gender === 'm' ? 'Macho' : 'Fêmea';
            const defaultAvatar = (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  padding: 8
                }}
              >
                {species.toLowerCase() === 'gato' ? (
                  <CatAvatar />
                ) : (
                  <DogAvatar />
                )}
              </div>
            );
            if (id !== '') {
              return (
                <li key={id} style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    {...(image ? { src: image } : { icon: defaultAvatar })}
                    size="large"
                  />
                  <div>
                    <p>{name}</p>
                    <p style={{ textTransform: 'capitalize' }}>
                      {species?.toLowerCase() === 'gato' ? 'Gato' : 'Cachorro'}{' '}
                      ({breed})
                    </p>
                    <p>
                      {formattedGender} (
                      {memorial ? 'Memorial' : getAge(birthday)})
                    </p>
                  </div>
                </li>
              );
            }
            return '-';
          })}
        </ul>
      );
    }
    return <p className="pl-10 mb-5">Não há pet cadastrado.</p>;
  };

  const showConfirmPush = type => {
    confirm({
      title: `Deseja enviar uma notificação para ${customer.name}?`,
      okText: 'Confirmar',
      centered: true,
      content: `Ao confirmar essa ação, o usuário será notificado via ${
        type === 'push' ? 'Push' : 'Whatsapp'
      }`,
      onOk() {
        if (type === 'push') pushNotificationFunction(type);
        else pushNotificationFunction(type);
        return false;
      },
      onCancel() {}
    });
  };

  return (
    <Card
      className="no-shadow space-left"
      title="Cliente"
      style={{ width: '100%' }}
      // extra={<Button>Ver Mais</Button>}
    >
      <section>
        <h4 className="text-bold">E-mail</h4>
        <p className="text-bold lh-half">{customer.email}</p>
      </section>
      <section>
        <h4 className="text-bold">Telefone</h4>
        <p className="text-bold lh-half">{customer.phone_number}</p>
      </section>
      <section style={{ marginBottom: 15 }}>
        <h4 className="text-bold">Tags</h4>
        <Tags
          removeTags={removeTags}
          addTags={addTags}
          tags={tags}
          newTag={editPermission}
          closable={editPermission}
        />
      </section>
      {ZEENOW_STORE && editPermission && (
        <section style={{ marginBottom: 15 }}>
          <h4 className="text-bold">Enviar Alerta</h4>

          {customer.notification_id ? (
            <Button
              className="text-blue"
              style={{ padding: 0, textTransform: 'none' }}
              type="link"
              onClick={() => showConfirmPush('push')}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginRight: 15
                }}
              >
                <img
                  style={{
                    marginRight: 3,
                    height: 22
                  }}
                  src={Push}
                  alt="Notificação push"
                />
                Push
              </div>
            </Button>
          ) : (
            <Tooltip
              placement="top"
              title="O cliente não tem a notificação push habilitada, tente outro tipo de envio."
            >
              <Button
                className="text-blue"
                style={{ padding: 0, textTransform: 'none' }}
                type="link"
                disabled={!customer.receive_notification}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginRight: 15
                  }}
                >
                  <img
                    style={{
                      marginRight: 3,
                      height: 22
                    }}
                    src={Push}
                    alt="Notificação push"
                  />
                  Push
                </div>
              </Button>
            </Tooltip>
          )}
          <Button
            className="text-blue"
            style={{ padding: 0, textTransform: 'none' }}
            type="link"
            onClick={() => showConfirmPush('whatsapp')}
          >
            <div style={{ display: 'flex' }}>
              <img
                style={{ marginRight: 3 }}
                src={Whatsapp}
                alt="Whatsapp notificação"
              />
              WhatsApp
            </div>
          </Button>
        </section>
      )}
      <div className={CustomerDetailsStyles['CustomerDetails__line--pets']}>
        <h4 className="text-bold">Pets</h4>
        {mountPetsList()}
      </div>
      <div className={CustomerDetailsStyles.CustomerDetails__line}>
        <h4 className="text-bold">Total de pedidos:</h4>
        <p>{customer.lifetime_orders_count}</p>
      </div>
      <div className={CustomerDetailsStyles.CustomerDetails__line}>
        <h4 className="text-bold">Valor Gasto Total:</h4>
        <p>{customer ? formatCurrency(customer.lifetime_total) : ''}</p>
      </div>
      <div className={CustomerDetailsStyles.CustomerDetails__line}>
        <h4 className="text-bold">Valor Médio:</h4>
        <p>{customer ? formatCurrency(customer.lifetime_average) : ''}</p>
      </div>
    </Card>
  );
};

CustomerDetails.propTypes = {
  customer: PropTypes.shape({
    lifetime_average: PropTypes.any,
    lifetime_orders_count: PropTypes.any,
    lifetime_total: PropTypes.any,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    name: PropTypes.string,
    receive_notification: PropTypes.bool,
    notification_id: PropTypes.string
  }).isRequired,
  pet: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        birthday: PropTypes.string,
        breed: PropTypes.string,
        gender: PropTypes.string,
        name: PropTypes.string,
        species: PropTypes.string
      })
    })
  ).isRequired,
  pushNotificationFunction: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  removeTags: PropTypes.func.isRequired,
  addTags: PropTypes.func.isRequired,
  editPermission: PropTypes.bool.isRequired
};

export default React.memo(CustomerDetails);
