import React from 'react';
import {
  Card,
  Avatar,
  Row,
  Col,
  Slider,
  Alert,
  Divider,
  Descriptions
} from 'antd';
import PropTypes from 'prop-types';

import moment from '@/src/services/moment';

import { ReactComponent as DogAvatar } from '@/src/assets/images/pets/dog.svg';
import { ReactComponent as CatAvatar } from '@/src/assets/images/pets/cat.svg';
import { getAge } from '@/src/components/Order/CustomerDetails/CustomerDetails';

const Pets = props => {
  const { pets } = props;

  const sizes = {
    mini: 'Mini (0,5kg a 1kg)',
    small: 'Pequeno (6kg a 10kg)',
    medium: 'Médio (11kg a 25kg)',
    large: 'Grande (26kg a 45kg)',
    giant: 'Gigante (acima de 45kg)'
  };

  const species = {
    gato: 'Gato',
    cat: 'Gato',
    cachorro: 'Cachorro',
    dog: 'Cachorro'
  };

  return (
    <Card className="no-shadow" title={<h2>Pets</h2>}>
      {pets.length > 0 ? (
        <>
          {pets.map((pet, index) => {
            const defaultAvatar = (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  padding: 8
                }}
              >
                {pet?.attributes?.species.toLowerCase() === 'gato' ? (
                  <CatAvatar />
                ) : (
                  <DogAvatar />
                )}
              </div>
            );
            return (
              <div key={pet.id}>
                <Row
                  type="flex"
                  align="middle"
                  gutter={8}
                  style={{ marginBottom: 8 }}
                >
                  <Col>
                    <Avatar
                      {...(pet?.attributes?.image
                        ? { src: pet?.attributes?.image }
                        : { icon: defaultAvatar })}
                      size="large"
                    />
                  </Col>
                  <Col>
                    <strong style={{ textTransform: 'capitalize' }}>
                      {pet?.attributes?.name}
                    </strong>
                  </Col>
                </Row>
                {pet?.attributes?.memorial && (
                  <Row
                    style={{
                      marginBottom: 12
                    }}
                  >
                    <Alert
                      message="Memorial"
                      description="Cliente não receberá comunicação sobre este pet."
                      type="info"
                      className="pets-alert"
                    />
                  </Row>
                )}
                <Row style={{ marginBottom: 8 }}>
                  <Descriptions size="small" column={1}>
                    <Descriptions.Item label="Espécie">
                      {pet?.attributes?.species
                        ? species[pet.attributes.species.toLowerCase()] ||
                          pet.attributes.species
                        : '(não informado)'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Sexo">
                      {pet?.attributes?.gender === 'm' ? 'Macho' : 'Fêmea'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Raça">
                      <span style={{ textTransform: 'capitalize' }}>
                        {pet?.attributes?.breed}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Porte">
                      {pet?.attributes?.size
                        ? sizes[pet.attributes.size] || pet.attributes.size
                        : '(não informado)'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nascimento">
                      {pet?.attributes?.birthday &&
                        moment(pet?.attributes?.birthday).format('MMMM/YYYY')}
                      {pet?.attributes?.memorial
                        ? ''
                        : ` (${getAge(pet?.attributes?.birthday)})`}
                    </Descriptions.Item>
                    <Descriptions.Item label="Temperamento (0/100)">
                      {pet?.attributes?.temperament || '(não informado)'}
                    </Descriptions.Item>
                  </Descriptions>
                </Row>
                {pet?.attributes?.temperament && (
                  <Row style={{ marginBottom: 8, padding: '0 12px' }}>
                    <Slider
                      marks={{
                        0: 'Tranquilo',
                        50: 'Equilibrado',
                        100: 'Agitado'
                      }}
                      value={pet?.attributes?.temperament}
                      disabled
                      included={false}
                      className="pet-temperament-slider"
                    />
                  </Row>
                )}
                {index < pets.length - 1 && <Divider />}
              </div>
            );
          })}
        </>
      ) : (
        <p>Cliente não tem pets cadastrados</p>
      )}
    </Card>
  );
};

Pets.defaultProps = {
  pets: []
};

Pets.propTypes = {
  pets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
        gender: PropTypes.string,
        breed: PropTypes.string,
        species: PropTypes.string,
        birthday: PropTypes.string,
        image: PropTypes.string
      })
    })
  )
};

export default Pets;
