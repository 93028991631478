import React, { useState, useEffect, useRef } from 'react';

import Tables from '@/src/components/Tables';
import { Row, Icon, Collapse, Button, Popconfirm, Input } from 'antd';
import PropTypes from 'prop-types';

import MarketingTagCardStyles from '@/src/components/Promotions/MarketingTagCard/MarketingTagCard.module.scss';

const { Panel } = Collapse;

const EditableInput = ({
  text,
  record,
  dataIndex,
  propIndex,
  mtagIndex,
  setMtags,
  mtags
}) => {
  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(text);
  const inputRef = useRef();

  useEffect(() => {
    if (dataIndex === 'key') setEditing(true);
    // eslint-disable-next-line
  }, []);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async e => {
    try {
      const newData = JSON.parse(JSON.stringify(mtags));
      newData[mtagIndex].properties[propIndex][dataIndex] = currentValue;
      setMtags(newData);
      toggleEdit();
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const onInputChange = e => {
    setCurrentValue(e.target.value);
  };

  const onKeyDown = (event, column) => {
    if (event.key === 'Tab' && column === 'key') {
      const nextInput =
        document.activeElement?.parentElement?.nextSibling?.firstChild;
      if (nextInput) nextInput.click();
    }
  };

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  if (editing) {
    return (
      <Input
        placeholder={
          dataIndex === 'key' ? 'Exemplo: utm_source' : 'Exemplo: google'
        }
        ref={inputRef}
        value={currentValue}
        onChange={onInputChange}
        onPressEnter={save}
        onBlur={save}
        onKeyDown={e => onKeyDown(e, dataIndex)}
      />
    );
  }
  return (
    <button
      type="button"
      style={{
        background: 'transparent',
        width: '100%',
        textAlign: 'left'
      }}
      key="edit-prop"
      className={MarketingTagCardStyles['editable-cell-value-wrap']}
      onClick={() => toggleEdit()}
    >
      {text}
    </button>
  );
};

const MarketingTagCard = props => {
  const { mtags, setMtags, editPromo } = props;
  const [hasError, setHasError] = useState(false);

  const handleAddOrRemoveMTag = (isToAdd, id) => {
    let mtagsValue = mtags;
    if (isToAdd) {
      mtagsValue.push({
        id: `mtag-id${mtagsValue.length}`,
        properties: []
      });
    } else {
      mtagsValue = mtagsValue.filter(mtag => mtag.id !== id);
    }
    setMtags(Object.values(mtagsValue));
  };

  const addOrRemoveParam = (isAdd, mtagIndex, propKey) => {
    const mtagsValue = mtags.map((mtag, idx) => {
      let newDataProp = [];
      const mtagProps = mtag.properties;
      if (mtagIndex === idx) {
        const previusProp = mtagProps[mtagProps.length - 1] || false;
        const canAdd =
          !previusProp || (previusProp.key !== '' && previusProp.value !== '');

        if (!canAdd) {
          setHasError({
            index: mtagIndex,
            msg: 'É necessário que o parâmetro e o valor sejam preechidos.'
          });
        }
        if (isAdd && canAdd) {
          setHasError({});
          const newProp = {
            id: `mtag-propid${mtagProps.length}`,
            key: '',
            value: ''
          };
          newDataProp = [...mtagProps, newProp];
        } else {
          if (!isAdd) setHasError({});
          newDataProp = mtagProps.filter(propItem => propItem.key !== propKey);
        }
      } else {
        newDataProp = mtagProps;
      }
      return { ...mtag, properties: newDataProp };
    });

    setMtags(Object.values(mtagsValue));
  };

  const columns = mtagIndex => {
    return [
      {
        title: 'Parâmetro',
        editable: true,
        dataIndex: 'key',
        width: '50%',
        render: (text, record, index) => {
          return (
            <EditableInput
              text={text}
              record={record}
              dataIndex="key"
              propIndex={index}
              mtagIndex={mtagIndex}
              setMtags={setMtags}
              mtags={mtags}
            />
          );
        }
      },
      {
        title: 'Valor',
        editable: true,
        dataIndex: 'value',
        width: '50%',
        render: (text, record, index) => {
          return (
            <EditableInput
              text={text}
              record={record}
              dataIndex="value"
              propIndex={index}
              mtagIndex={mtagIndex}
              setMtags={setMtags}
              mtags={mtags}
            />
          );
        }
      },
      {
        title: '',
        width: 40,
        render: record => {
          return (
            <Popconfirm
              title="Remover?"
              onConfirm={() => addOrRemoveParam(false, mtagIndex, record?.key)}
            >
              <Icon type="delete" />
            </Popconfirm>
          );
        }
      }
    ];
  };

  const deleteIcon = id => (
    <Popconfirm
      title="Remover?"
      onConfirm={() => handleAddOrRemoveMTag(false, id)}
    >
      <Icon
        type="delete"
        onClick={event => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
        }}
      />
    </Popconfirm>
  );

  return (
    <>
      <Row style={{ marginTop: 20 }}>
        <h2 style={{ marginBottom: 0 }}> MARKETING TAGS </h2>

        <Row>
          <span style={{ marginBottom: 0 }}>
            Blocos de parâmetros na URL que ativam a promoção. Diferentes
            combinações podem ativar a mesma promoção.
          </span>
        </Row>
        <Row>
          <Button
            className="ant-btn ant-btn-link"
            style={{
              color: '#000000D9',
              padding: '0',
              margin: '8px 0'
            }}
            onClick={() => handleAddOrRemoveMTag(true)}
            disabled={!editPromo}
          >
            <Icon type="plus-circle" />
            Adicionar Marketing Tag
          </Button>
        </Row>

        <Collapse style={{ marginBottom: 12 }}>
          {mtags?.map((mtag, index) => {
            return (
              <Panel
                key={`bloco-${index}`}
                style={{ border: '1px solid #ebebeb', borderRadius: 4 }}
                extra={deleteIcon(mtag.id)}
                header={<span>Bloco {index + 1}</span>}
              >
                <Button
                  className="ant-btn ant-btn-link"
                  style={{
                    color: '#000000D9',
                    padding: '0'
                  }}
                  onClick={() => addOrRemoveParam(true, index, mtag)}
                  disabled={!editPromo}
                >
                  <Icon type="plus-circle" />
                  Adicionar Parâmetro
                </Button>
                {hasError?.index === index && (
                  <div>
                    <span style={{ color: '#ff365c' }}>{hasError?.msg}</span>
                  </div>
                )}
                <Tables
                  rowClassName={() => 'editable-row'}
                  columnsDefaults={columns(index)}
                  data={mtag.properties}
                />
              </Panel>
            );
          })}
        </Collapse>
      </Row>
    </>
  );
};

EditableInput.propTypes = {
  text: PropTypes.string.isRequired,
  record: PropTypes.any.isRequired,
  dataIndex: PropTypes.string.isRequired,
  propIndex: PropTypes.number.isRequired,
  mtagIndex: PropTypes.number.isRequired,
  setMtags: PropTypes.func.isRequired,
  mtags: PropTypes.any.isRequired
};

MarketingTagCard.propTypes = {
  mtags: PropTypes.any.isRequired,
  setMtags: PropTypes.func.isRequired,
  editPromo: PropTypes.bool.isRequired
};

export default MarketingTagCard;
